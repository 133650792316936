.auctionOrderForm {
  width: 100%;
  height: 100%;
  border-left: 3px solid var(--new-teal-60);
  background-color: var(--new-ink-20);
  overflow-y: scroll;
  padding-bottom: 64px;

  &.buyBorder {
    border-color: var(--new-teal-60);
  }

  &.sellBorder {
    border-color: var(--new-red-60);
  }

  .tabs {
    display: flex;

    .tab {
      position: relative;
      height: auto;
      border: none;
      background-color: inherit;
      max-width: 134px;
      padding: 14px 8px;
      line-height: 12px;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        background-color: inherit;
      }

      // active highlight bar
      &:after {
        position: absolute;
        display: none;
        bottom: 0;
        right: 0;
        left: 0;
        height: 2px;
        background-color: var(--blue-60);
        content: "";
      }
    }

    .activeTab {
      color: var(--blue-60);

      &:after {
        display: block;
      }
    }
  }

  .buySubmitButton {
    background-color: var(--new-teal-60) !important;
    color: var(--ink-90) !important;
    cursor: pointer;

    &:hover {
      background-color: var(--new-teal-50) !important;
    }

    &:active {
      background-color: var(--new-teal-40) !important;
    }
  }

  .sellSubmitButton {
    background-color: var(--new-red-60) !important;
    cursor: pointer;

    &:hover {
      background-color: var(--new-red-50) !important;
    }

    &:active {
      background-color: var(--new-red-40) !important;
    }
  }
}

.closeIcon {
  color: var(--new-ink-100);
  position: absolute;
  right: 21px;
  top: 67px;
  z-index: 1;

  svg {
    cursor: pointer;
    display: block;
    fill: var(--new-ink-100);
    margin: auto;
    opacity: 0.8;
  }
}

.labelBlock {
  width: 109px;
}

.orderMain {
  display: flex;
  margin: auto;
  padding: 12px;
  flex-wrap: wrap;
  gap: 6px;

  .title {
    margin-bottom: 8px;
    padding-left: 4px;
    font-size: 14px;
    font-weight: 700;
  }
}

.containerTwoCol {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .inputBlockTwoCol {
    width: 48%;

    .inputSection {
      position: relative;
      width: auto;
    }

    .priceAdjustButton,
    .priceRefresh {
      right: 49px;

      + .priceAdjustButton {
        right: 25px;
      }

      &.priceRefresh {
        right: 1px;
      }
    }

    .inputPriceTwoCol {
      max-width: 53%;
    }

    .selectDisabled {
      color: var(--new-ink-40);
    }
  }

  .inputBlockTwoColPill {
    width: 48%;

    .inputSection {
      min-width: 297px;
    }
  }
}

.inputBlock {
  display: flex;
  padding: 0 0 8px 4px;
  flex-direction: column;

  > label {
    font-size: 12px;
    color: var(--new-ink-100);
    font-weight: 600;
    line-height: 12px;
    padding-bottom: 4px;
    word-break: keep-all;
  }

  // .buy > div {
  //   color: var(--new-teal-60);
  // }

  // .sell > div {
  //   color: var(--new-red-60);
  // }
}

.inputSection {
  width: 297px;
  font-size: 12px;

  .inputPrice {
    max-width: 78%;
  }

  input {
    &:invalid {
      box-shadow: 0;
    }
  }
}

.inputDisabled {
  > div {
    background-color: transparent;
    color: var(--new-ink-40);
    border-color: var(--new-ink-40);

    &:hover {
      border-color: var(--new-ink-40);
      cursor: not-allowed;
    }
  }
}

.fieldHidden {
  display: none;
}

.inputSectionSmall {
  display: flex;

  .priceAdjustButton,
  .priceRefresh {
    width: 24px;
    padding: 0 4px;
    position: absolute;
    right: 75px;

    + .priceAdjustButton {
      right: 51px;
    }

    &.priceRefresh {
      border: 0;
      color: var(--new-ink-100);
      right: 27px;

      &[disabled] {
        background: transparent;

        svg {
          fill: var(--new-ink-40);
        }
      }
    }
  }
}

// TODO: Use a more generic Prism component for this toggle.
.radioToggle {
  background: var(--new-ink-10);
  border: 1px solid var(--new-ink-50);
  border-radius: 4px;
  color: var(--new-ink-50);
  line-height: 16px;
  margin: 0;
  padding: 2px 13px;
  // padding: 5px 12px;

  &:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  > :first-child {
    display: none;
  }
}

.radioDisabled {
  cursor: not-allowed;
}

// The "Buy" option is selected in Buy mode.
.buy {
  .radioToggle {
    &:first-child {
      background: var(--new-teal-60);
      border-color: var(--new-teal-60);
      color: var(--new-ink-10);
    }
  }
}

// The "Sell" option is selected in Sell mode.
.sell {
  .radioToggle {
    &:last-child {
      background: var(--new-red-60);
      border-color: var(--new-red-60);
      color: var(--new-ink-100);
    }
  }
}

.auctionActions {
  display: flex;
  justify-content: flex-end;
  width: calc(var(--ordersPanelWidth) - 4px);
  padding: 16px;
  margin-left: -8px;
  align-items: center;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);
  gap: 9px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: var(--new-ink-20);

  button {
    height: auto;
    padding: 8px 16px;
  }

  .cancelButton {
    color: var(--new-ink-100);
  }
}

.orderStatus {
  width: 100%;
  height: 64px;
  background: var(--new-ink-20);
  border-radius: 0px 0px 10px 10px;
  left: 2px;
  right: 2px;
  bottom: 2px;
}

.orderStatusHigh {
  height: 128px;
}

.spaceBlock {
  height: 24px;
  width: 100%;
}

.currencyCode {
  color: var(--new-yellow-50);
}

.cardHeader {
  width: 100%;
  display: flex;
  color: var(--new-ink-90);
  font-size: 12px;
  gap: 8px;
  line-height: 16px;
  padding: 15px 16px 7px;

  .titleRow {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 3px;

    .currency {
      font-weight: 700;
    }

    .date {
      font-weight: 400;
    }
  }

  .priceRow {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    gap: 3px;
  }

  .bigNum {
    font-size: 20px;
  }

  .cardOverflowButton {
    width: 24px;
    color: var(--ink-00);
  }
}
