  .ant-calendar-picker, .ant-calendar-picker-input{
    cursor: pointer;
  }

  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: rgba(var(--ink-00-rgb), 0.64);
  }

  .datePickerCalendar {
    .ant-calendar {
      background-color: var(--ink-80);
      border: none;
      box-shadow: 0px 0px 7px 0px;
    }
  
    .ant-calendar-panel {
      color: var(--ink-00);
    }
  
    .ant-calendar-input-wrap {
      height: auto;
      padding: 0;
      border-bottom: none;
  
      .ant-calendar-input {
        height: 28px;
        padding: 4px 11px;
        background-color: var(--ink-90);
        color: inherit;
        font-size: 14px;
      }
    }
  
    .ant-calendar-header {
      font-size: 14px;
      border-bottom: 1px solid var(--ink-90);
  
      .ant-calendar-century-select,
      .ant-calendar-decade-select,
      .ant-calendar-year-select,
      .ant-calendar-month-select {
        color: rgba(255, 255, 255, 0.85);
      }
  
      & a:hover {
        color: var(--ink-00);
      }
    }
  
    .ant-calendar-year-panel-header,
    .ant-calendar-month-panel-header,
    .ant-calendar-header,
    .ant-calendar-decade-panel-header {
      border-bottom: 1px solid var(--ink-90);
    }
  
    .ant-calendar-selected-day .ant-calendar-date,
    .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
    .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
    .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
      background-color: var(--teal-60);
      border-radius: 9px;
      border: none;
      color: var(--ink-90);
  
      &:hover {
        background-color: var(--teal-60);
      }
    }
  
    .ant-calendar-date {
      font-size: 12px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.65);
  
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  
    .ant-calendar-month-panel-header,
    .ant-calendar-month-panel-body,
    .ant-calendar-year-panel-header,
    .ant-calendar-year-panel-body,
    .ant-calendar-decade-panel-header,
    .ant-calendar-decade-panel-body {
      background-color: var(--ink-80);
  
      a {
        color: var(--ink-00);
  
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  
    .ant-calendar-month-panel-header a:hover,
    .ant-calendar-year-panel-header a:hover,
    .ant-calendar-decade-panel-header a:hover {
      background-color: transparent;
      color: var(--ink-00);
    }
  
    .ant-calendar-today .ant-calendar-date {
      border-color: var(--teal-60);
    }
  
    .ant-calendar-last-month-cell .ant-calendar-date,
    .ant-calendar-next-month-btn-day .ant-calendar-date,
    .ant-calendar-last-month-cell .ant-calendar-date:hover,
    .ant-calendar-next-month-btn-day .ant-calendar-date:hover {
      color: rgba(255, 255, 255, 0.25);
    }
  
    .ant-calendar-today-btn {
      color: var(--teal-60);
    }
  
    .ant-calendar-footer {
      border-top: 1px solid var(--ink-90);
    }
  }
  