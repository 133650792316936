.orderRibbon {
  background-color: var(--new-ink-20);
  display: flex;
  height: 40px;
  justify-content: space-between;
  width: 100%;
}

.radioGroup {
  background-color: var(--new-ink-20);
  border-bottom: 1px solid var(--new-ink-10);
  display: flex;
  padding: 8px;

  > div {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.orderSize,
.orderStrategy,
.oddsAndEnds {
  border-right: 1px solid var(--new-ink-00);
}

.theRest {
  flex-grow: 1;
}

.icebergInput {
  font-size: 14px;
  width: 80px;
}

.orderLifespanSelect {
  width: 78px;
}

.onBehalfSection {
  border-right: 1px solid var(--new-ink-00);

  > div {
    margin-right: 8px;
  }
}

.orderTypeSelect {
  width: 100px;
}

.onBehalfOfSelect {
  width: 144px;
}

.tradersSelect {
  width: 112px;
}

.icebergButton {
  text-transform: capitalize;
}

.orderRibbonContextMenu {
  font-size: 16px;
  width: 256px;
}
