/*
  This is a CSS only styling, the goal being to style the scrollbars on whatever
  browsers can handle it. A Couple of things to note

  1. Styling the scrollbar makes it permenantly render visually. This is the intended effect
  2. There is no way to 'pad' the bar background from the bar, so we use a clever trick by Anthony
  where you give the scroll bar a border that matches the BG
  3. Styling is unconfirmed in IE and FF, so results and options are mixed depending on the browser.

  Guide: https://css-tricks.com/almanac/properties/s/scrollbar/
*/

/* Webkit scrollbar styling */
::-webkit-scrollbar,
::-webkit-scrollbar-button,
::-webkit-scrollbar-corner,
::-webkit-scrollbar-resizer,
::-webkit-scrollbar-track {
  background: var(--scrollBarTrackColor);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollBarColor);
  border: solid 4px var(--scrollBarTrackColor);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollBarColorHover);
}

::-webkit-scrollbar-thumb:active {
  background: var(--scrollBarColorPress);
}

// Microsoft IE scrollbar styling - don't use variables as they might not be supported
* {
  -ms-scrollbar-3dlight-color: #002851;
  -ms-scrollbar-arrow-color: #3c5b7a;
  -ms-scrollbar-base-color: #002851;
  -ms-scrollbar-darkshadow-color: #002851;
  -ms-scrollbar-face-color: #3c5b7a;
  -ms-scrollbar-highlight-color: #002851;
  -ms-scrollbar-shadow-color: #002851;
  -ms-scrollbar-track-color: #3c5b7a;

  // Old Microsoft IE and Firefox scrollbar styling
  scrollbar-3dlight-color: #002851;
  scrollbar-arrow-color: #3c5b7a;
  scrollbar-base-color: #002851;
  scrollbar-darkshadow-color: #002851;
  scrollbar-face-color: #3c5b7a;
  scrollbar-highlight-color: #002851;
  scrollbar-shadow-color: #002851;
  scrollbar-track-color: #002851;

  // Current Firefox scrollbar styling
  scrollbar-color: var(--scrollBarColor) var(--scrollBarTrackColor);
}
