/*
  Dropdown - common component used to render menu items for different parent components.
  Dropdowns typically share the same core class, but also vary component by component
  (thanks atnd) so theres some local styling variations based on the context.

  Note: that Dropdown HTML is typically portaled outside of the parent that opened
  up the menu, so if you're trying to style based on a parent class, odds are it wont work

  Usage:
    None directly, these are auto created when you use:
      * select
      * typical menu (dropdown)
      * table filters

  Design
    https://www.figma.com/file/bDra0jQUuM6KqDA2CPNIvU7e/Documentation?node-id=78%3A1
*/

@at-root {
  // base dropdown
  .ant-dropdown-menu {
    padding: 4px 0;
    min-width: 128px;
    background-color: var(--new-ink-30);
  }

  .ant-dropdown-menu-item {
    position: relative;
    height: 40px;
    background-color: transparent;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;
    min-width: 120px;

    &:hover {
      background-color: var(--new-ink-20);
    }

    &:active {
      background-color: var(--new-ink-20);
      outline: none;
    }

    // blue active dot, only applicable on 'selectable' menu items
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 6px;
      display: none;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin: auto 0;
      content: "";
      background-color: var(--blue-60);
    }

    &.ant-dropdown-menu-item-selected {
      color: var(--ink-00);
      font-weight: normal;
      background-color: transparent;

      &::after {
        display: block;
      }
    }

    // in the case of table filters, hide the checkbox as the indicator is handled by the blue dot
    .ant-checkbox-wrapper {
      display: none;

      + span {
        padding-left: 0;
      }
    }
  }
}
