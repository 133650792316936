.dropdown {
  // the following !importants are because I need overrides specific to the card slippage section
  display: flex;
  width: 112px;
  align-items: center;
  justify-content: center;

  .button {
    color: var(--new-ink-100);
    background-color: var(--new-ink-10);
    width: 112px;
    // margin-right: 4px;
    justify-content: space-between;
    font-size: 14px;
    .buttonContent {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // display: inline-block;
    }

    i {
      font-size: 14px;
    }

    &:hover,
    &:focus {
      border-color: var(--new-ink-100);
    }
  }
}

.dropdownSection {
  position: relative;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.dropdownContent {
  display: block;
  position: absolute;
  top: 12px;
  left: -110px;
  background-color: var(--new-ink-30);
  z-index: 1;
  width: 256px;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  background-clip: padding-box;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

  &:focus {
    outline: none;
  }

  .dropdownMenu {
    height: 100%;
    // background-color: var(--new-ink-30);
    padding: 4px 0px;
    border-radius: 4px;
  }
}

.listItem {
  margin-left: 20px;
  height: 32px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
}

.selected {
  top: 6px;
  left: -20px;
  height: 4px;
  width: 4px;
  background-color: var(--blue-60);
  border-radius: 50%;
  display: inline-block;
  margin: 8px;
  position: absolute;
}

.filterInput {
  padding: 8px 8px 0px 8px;
  input::-webkit-input-placeholder {
    opacity: 0.4;
  }
  input:-ms-input-placeholder {
    opacity: 0.4;
  }
  input::placeholder {
    opacity: 0.4;
  }
}
