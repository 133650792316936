.no-blotter {
  background-color: var(--new-ink-20);

  .blank-order-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: var(--new-ink-20);
  }
  .blank-text {
    text-align: center;
    font-size: 16px;
    color: var(--ink-00);
    opacity: 0.64;
  }
}

.ag-theme-balham-dark.ag-dnd-ghost {
  background-color: var(--new-ink-20);
  border-color: var(--new-ink-50);
}

.exportContainer {
  background-color: var(--new-ink-20);
  display: flex;
  justify-content: flex-end;
  padding: 20px 12px 12px 0;

  .exportButton {
    font-size: 14px;
    height: auto;
    padding: 8px 16px;
  }
}

@import "@ag-grid-community/core/dist/styles/ag-grid.scss";
@import "@ag-grid-community/core/dist/styles/ag-theme-balham-dark/sass/_ag-theme-balham-dark-mixin.scss";

.ag-theme-balham-dark {
  @include ag-theme-balham-dark(
    (
      list-item-height: 30px,
    )
  );
}

.ag-theme-balham-dark.ag-theme-prism {
  width: 100%;
  height: 100%;
  background-color: var(--new-ink-20);
  font-family: inherit;

  // container for entire grid
  .ag-root {
    border: none;
  }

  .ag-root-wrapper {
    background: transparent;
    border: none;

    .ag-root-wrapper {
      background-color: var(--new-ink-20);
      //  border: 1px solid #3c5b7a;
      // border-radius: 3px;
      height: 100%;
    }
  }

  .ag-details-grid {
    position: relative;
  }

  .ag-react-container {
    height: 100%;
  }

  /*
     GRID HEADER:
     Contains all column header cells, and their respective action buttons.
   */

  .ag-header {
    background-color: var(--new-ink-20);
    border: none;
  }

  .ag-header-cell {
    border: none;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(var(--ink-00-rgb), 0.64);

    // remove vertical 'border' separator
    &::after {
      background: transparent;
      border: none;
    }

    // header cell 'left behind' while a drag is active
    &.ag-header-cell-moving {
      background-color: var(--ink-60);
    }

    &.ag-header-cell-filtered {
      .ag-filter-icon {
        display: none;
      }
      // force the menu icon to show when we have an active filter
      .ag-header-cell-menu-button {
        opacity: 1 !important; // have to overwrite inline style
        fill: var(--blue-60);
      }
    }

    .ag-header-cell-resize {
      &:after {
        background: var(--blue-60);
        content: "";
        display: block;
        height: 100%;
        opacity: 0;
        position: absolute;
        right: 4px;
        top: 0;
        width: 2px;
      }

      &:hover:after {
        opacity: 1;
      }
    }

    // on hover, show header menu button
    &:hover .ag-header-icon.ag-header-cell-menu-button {
      opacity: 1;
    }

    // turn off column header filter icon, it only shows up as an indicator
    &:hover .ag-header-icon.ag-filter-icon {
      display: none;
    }
  }

  // copied from balham
  .ag-header-cell-label {
    display: flex;
    flex: 1;
    overflow: hidden;
    align-items: center;
    text-overflow: ellipsis;
    align-self: stretch;
  }

  .ag-header-cell-text {
    margin-right: auto;
    font-weight: 400;
  }

  .ag-pinned-left-header,
  .ag-pinned-left-cols-container {
    border-right: 1px solid var(--ink-90);

    .ag-react-container{
      display: flex;
      align-items: center;
    }

    .ag-cell-last-left-pinned{
      padding-right: 0;
    }
  }
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell),
  .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
    border-right: none;
  }


  /*
     GRID ROWS:
     Contains all data cells cells
   */
  .ag-row {
    border: none;
    height: 24px;
    font-weight: 400;

    &.ag-row-odd {
      background-color: var(--new-ink-20);
    }

    &.ag-row-even {
      background-color: var(--new-ink-15);
    }

    &.ag-row-selected {
      background: var(--new-blue-30);
    }
  }

  .ag-cell {
    border: none;
    line-height: 24px;
    padding-left: 8px;
    padding-right: 8px;
    font-family: inherit;
    font-size: 12px;
    font-weight: 400;
    color: var(--ink-00);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .masterDetailCell {
    height: 100%;
    width: 100%;

    svg {
      display: block;
      fill: var(--ink-00);
      opacity: 0.8;
    }
  }

  .ag-cell-value {
    width: 100%;
  }

  // deal with the sort icon for hover, and active states
  .ag-cell-label-container {
    &:hover.ag-header-cell-sorted-none {
      .ag-sort-none-icon {
        display: block !important; // have to overwrite inline style
        height: 24px;
        width: 24px;
        fill: var(--ink-00);
        opacity: 1;
      }
    }

    .ag-header-icon {
      opacity: 0;
      transition: none !important; // have to overwrite inline style
      fill: var(--ink-00);
      margin-left: 4px;
      display: flex;
      svg {
        fill-opacity: 0.8;
      }

      &:hover {
        background-color: rgba(var(--ink-00-rgb), 0.24);
        border-radius: 3px;
        svg {
          fill-opacity: 1;
        }
      }

      &.ag-sort-ascending-icon,
      &.ag-sort-descending-icon {
        fill: var(--blue-60);
        opacity: 1;
      }

      svg,
      path {
        fill: inherit;
        stroke: inherit;
      }
    }
  }

  /* --------
     Our own custom cell classes. These are added in `blotter-columns.ts` column definitions
   */

  /* Cells have left/right padding by default, but in some cases we want the content to be flush */
  .ag-cell-flush {
    padding: 0;
  }

  /*
     Indicator has no cell value, so by default the inner cell styles don't have a height.
     Manually force 100% so the vertical bar in the renderer shows up
   */
  .ag-cell-user-indicator {
    .ag-cell-wrapper,
    .ag-cell-wrapper {
      display: block;
      height: 100%;
    }
  }

  /*
     Indicator has no cell value, so by default the inner cell styles don't have a height.
     Manually force 100% so the vertical bar in the renderer shows up
   */
  .ag-cell-user-indicator {
    .ag-cell-wrapper,
    .ag-cell-wrapper {
      display: block;
      height: 100%;
    }
  }

  /* Contains the icon button to show/hide blotter detail grid */
  .ag-cell-expand {
    /*
       Expand icons for rows with details. There are two icons 'buttons', expand and contract, and ag-grid hides
       whichever is currently active
     */
    .ag-group-expanded,
    .ag-group-contracted {
      width: 24px;
      height: 24px;
      justify-content: center;

      &:hover {
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  /*
     Ag Menu. This can be displayed by hovering over a column in the table
     and clicking on the right most 'three line' menu button.
     The menu itself contains options such as filters and column show/hide options
   */

  // outer wrapper for entire menu
  .ag-menu {
    background-color: var(--new-ink-20);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
    border: none;
    border-radius: 3px;
    width: 192px;
    background-color: var(--new-ink-30);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
    overflow: hidden;
  }

  // menu tabs, used to toggle between diff menu panels
  .ag-tabs-header {
    background: transparent;
    border-bottom: 1px solid var(--new-ink-00);
    width: 100%;
    min-width: unset; // don't need the balham theme setting
  }

  // single tab within the menu header
  .ag-tab {
    box-sizing: border-box;
    flex: 1;
    border: none;
    height: 24px;
    margin: 0px;
    padding: 0px;
    color: rgba(var(--ink-00-rgb), 0.64);
    cursor: pointer;

    &:hover,
    &.ag-tab-selected {
      color: rgba(var(--ink-00-rgb), 1);
    }

    &.ag-tab-selected {
      background-color: inherit;
      border: none;
      box-shadow: inset 0 -2px 0 var(--blue-60); // blue highlight bar
    }
  }

  // content section of the selected tab
  .ag-tabs-body {
    background-color: inherit;
    width: 100%;
    max-height: 128px; // force a scroll on tabs that are too tall
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }

  /*
     FILTERS TAB:
     These are the individual inputs and wrappers that render inside of
     the filter tab of a column menu.

     Debt
     - virtual-filter sets a position auto and height of 28px for some reason
     - style a date input (calendar select)
     - change filter `AND` to `And` as well as make them radio buttons
   */

  // specific filter wrapper for a popover menu
  // don't pad this because it also wraps filter checkbox list items, and padding will push the scroll bar away from edge
  .ag-filter-body-wrapper {
    padding: 0 0 8px;
  }

  // header inside of a filter body section. usually contains hidden elements anyway
  .ag-filter-header-container {
    display: none;
  }

  .ag-virtual-list-viewport {
    padding: 6px 8px;
  }

  .ag-list-item,
  .ag-virtual-list-item {
    height: unset;
    font-size: 16px;
    padding: 6px 0;

    .ag-checkbox {
      margin-right: 8px;
    }
  }

  .ag-filter {
    max-height: 140px;
    overflow-y: auto;
    .ag-filter-body {
      padding-top: 8px;
    }
    .ag-input-wrapper.ag-filter-number-to {
      display: none;
    }
  }

  // wrapper for a single element within the body
  .ag-filter-body {
    margin-bottom: 0;
  }

  // selects don't have a wrapper of sorts
  .ag-filter-select {
    margin: 8px 8px 0;
    height: auto;
  }

  // and/or radio option list
  .ag-filter-condition {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 8px;

    > label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      height: 32px;
      margin-right: 8px;

      input[type="radio"] {
        margin-right: 8px;
      }
    }
  }

  // range input wrapper, which is otherwise a normal text input
  .ag-filter-number-to {
    margin-top: 8px;
  }

  // 'set' is typically a checkbox list inside of a filter
  .ag-set-filter-list {
    height: auto;
    margin-bottom: -8px; // no padding with overflow

    .ag-set-filter-item {
      height: unset;
      margin: 0;
    }

    // checkboxes in filters
    .ag-filter-checkbox {
      margin: 0;
    }

    // labels, e.g. a label next to a filter checkbox
    .ag-filter-value {
      padding-left: 8px;
      margin: 0;
      font-size: 12px;
      line-height: 24px;
    }
  }

  /*
     COLUMNS TAB:
     A list of show/hide toggles for each column in the table.
     Sub-Tab of the table dropdown menu
   */

  .ag-menu-column-select-wrapper {
    height: unset; // let the tab overflow, not the panel inside
  }

  // body of the panel
  .ag-column-select-panel {
    min-height: 140px;
  }

  // wrapper for the column checkbox items list
  .ag-primary-cols-list-panel {
    border-top: 0;
    padding: 4px 0;
  }

  // checkbox row wrapper
  .ag-column-tool-panel-column {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 8px;
    height: 24px;

    // select-all switch, doesn't seem to work so we hide it
    &.hideColumnFromColumnFilter {
      display: none;
    }

    // checkbox + label
    .ag-column-select-checkbox {
      margin: 0;
    }

    .ag-column-tool-panel-column-label {
      margin: 0;
      padding-left: 8px;
      font-size: 12px;
      line-height: 24px;
    }
  }

  /*
     MENU TAB:
     Menu options tab (e.g. 'auto size this column')
   */

  // menu wrapper
  .ag-menu-list {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  // breaks up sections inside of a menu tab
  .ag-menu-separator {
    display: none;
  }

  // single option item
  .ag-menu-option {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 8px;

    &:hover {
      background-color: var(--new-ink-20);
    }
  }

  .ag-menu-option-part {
    font-size: 16px;
    padding: 0px 4px;
  }

  // icon to the left of option label. this didnt seem to show up in the first place.
  .ag-menu-option-icon {
    display: none;
  }

  .ag-menu-option-text {
    margin-left: 0;
  }

  /*

     GENERIC INPUTS:
     Input fields found throughout the agGrid interfaces. These are styled generically.
     If you need specific style exceptions, check the sections the input renders in
     (e.g. menu filter dropdown)
   */

  .ag-filter {
    input[type="text"],
    input[type="number"],
    input[type="date"] {
      box-sizing: border-box;
      width: 100%;
      height: 32px;
      border: 1px solid;
      border-color: var(--new-ink-50);
      border-radius: 3px;
      margin: 8px 8px 0 8px;
      outline: none;
      padding: 0 12px !important; // have to overwrite inline style
      background-color: var(--new-ink-20);
      font-size: 16px;

      &::placeholder {
        color: rgba(var(--ink-00-rgb), 0.4);
      }

      &:focus {
        border-color: var(--new-ink-50);
        box-shadow: none;
      }
    }
  }

  // date input overrides
  input[type="date"] {
    margin: 8px 8px 0;
    max-width: calc(100% - 16px); // width minus x-padding
    padding: 0 4px 0 12px !important; // overrides inline style

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
      opacity: 0.8;
    }
  }

  // radio input
  input[type="radio"] {
    position: relative;
    box-sizing: border-box;
    appearance: none;
    border: 1px solid rgba(var(--ink-00-rgb), 0.64);
    border-radius: 12px;
    width: 16px;
    height: 16px;
    padding: 0;
    margin: 0;
    background: transparent;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: none;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: rgba(var(--ink-00-rgb), 0.88);
    }

    &:checked::after {
      display: block;
    }

    &:hover {
      box-shadow: 0 0 0 4px rgba(var(--ink-00-rgb), 0.32);
    }
  }

  .ag-input-wrapper {
    max-width: 100%;
  }

  // checkbox - grid hides the actual input and creates a presentational wrapper around it
  .ag-checkbox,
  .ag-filter-checkbox {
    .ag-label,
    .ag-column-select-column-label {
      font-size: 16px;
      margin-left: 8px;
      margin-right: 0;
    }

    span.ag-icon,
    .ag-checkbox-input-wrapper {
      position: relative;
      background: transparent;
      box-sizing: border-box;
      border: 1px solid;
      border-color: rgba(var(--ink-00-rgb), 24);
      border-radius: 3px;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      cursor: pointer;

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none;
        width: 13px;
        height: 11px;
        margin: auto;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 1.49999L4 9L0.999995 5.99999' stroke='%23002851' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
        background-position: center center;
        background-repeat: no-repeat;
        content: "";
      }

      &::after {
        display: none;
      }

      &.ag-icon-checkbox-checked,
      &.ag-checked {
        background: var(--teal-60);
        border-color: var(--teal-60);

        &::before {
          display: block;
        }
      }
    }
  }

  // select field
  .ag-filter-select .ag-wrapper,
  select {
    position: relative;
    appearance: none;
    height: 32px;
    border: 1px solid var(--new-ink-50);
    border-radius: 3px;
    background-color: var(--new-ink-20);
    margin: 0px;
    padding: 0 12px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 30px;
    // we cant use before or after, but we can use an svg background
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 5L10 -1.43051e-06L4.37114e-07 -8.74228e-07L5 5Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
    background-size: 10px;
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;

    &::-ms-expand {
      display: none;
    }
  }

  // additional select overrides
  .ag-select {
    .ag-picker-field-wrapper {
      box-shadow: none;
    }

    .ag-picker-field-display {
      margin: 0;
    }
  }

  .ag-filter-select {
    .ag-picker-field-icon {
      display: none;
    }
  }

  // TODO UNSORTED

  .ag-column-select-header {
    display: none;
  }

  .ag-details-grid {
    background-color: transparent;
  }

  .ag-details-row {
    background-color: var(--new-ink-20);
    padding: 8px;
  }

  .ag-status-bar {
    border: none;
  }

  // popup has the same parent class name, so we unset the dimensions
  &.ag-popup {
    width: auto;
    height: auto;
  }

  // select popup
  .ag-popup .ag-select-list {
    background: var(--new-ink-30);
    padding: 8px 0;

    .ag-list-item {
      color: var(--new-ink-100);
      cursor: pointer;
      padding: 8px 12px;

      &:hover,
      &:focus {
        background: rgba(var(--ink-90-rgb), 0.8);
      }

      &.ag-active-item,
      &:active {
        background: var(--new-ink-20);
      }

      &::placeholder {
        color: rgba(var(--ink-00-rgb), 0.6);
      }

      &:disabled {
        opacity: 0.6;
      }
    }
  }

  .ag-center-cols-container {
    min-width: 100%;
  }

  .ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.ag-cell-focus {
    border: none;
  }

  .ag-overlay-no-rows-center {
    width: 100%;
    height: 25px;
    position: absolute;
    top: 0px;
    background-color: var(--new-ink-20);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // custom class, see RowExpandCell component
  .row-expand-cell {
    height: 100%;
    width: 100%;

    svg {
      display: block;
      fill: var(--ink-00);
      opacity: 0.8;
    }
  }
}

.no-blotter {
  background-color: var(--new-ink-20);

  .blank-order-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: var(--new-ink-20);
  }
  .blank-text {
    text-align: center;
    font-size: 16px;
    color: var(--ink-00);
    opacity: 0.64;
  }
}

.ag-theme-balham-dark.ag-dnd-ghost {
  background-color: var(--new-ink-20);
  border-color: var(--new-ink-50);
}

div.ag-floating-top.ag-selectable {
  border: none;
  background-color: var(--new-ink-20);
  color: var(--ink-00);
  font-weight: 600;
}

// .ag-cell {
//   font-family: inherit;
//   font-size: 12px;
//   line-height: 24px;
//   padding-left: 8px;
//   padding-right: 8px;
//   // TODO @jared - why was this 200?
//   // font-weight: 200;
//   font-weight: 400;
//   border: none;
//   // TODO @jared - why did this font have opacity?
//   // color: rgba(var(--ink-00-rgb), 0.64);
//   color: var(--ink-00);
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// .masterDetailCell {
//   height: 100%;
//   width: 100%;

//   svg {
//     display: block;
//     fill: var(--ink-00);
//     opacity: 0.8;
//   }
// }


// SELECT ORDERS CHECKBOXES
.ant-checkbox-wrapper{

    &:hover{

      .ant-checkbox-inner{
        border-color: inherit;
      }
    }

} 

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #FFF;
}

.ant-checkbox-inner{
  background-color: transparent;
  border: 1px solid #FFF;
  border-radius: 3px;

  &:after {
    top: 43%;
    left: 13%;
    width: 5.714286px;
    height: 12.142857px;
  }

}

.ant-checkbox-checked {

  &:after{
    border-color: var(--teal-60);
    border-radius: 3px;
  }

  &:hover{
    .ant-checkbox-inner{
      border-color: var(--teal-60);
    }
  }

  .ant-checkbox-inner {
    background-color: var(--teal-60);
    border-color: var(--teal-60);
    border-radius: 3px;

    &:after {
      border: 2px solid var(--ink-80);
      border-top: 0;
      border-left: 0;
    }

  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--teal-60);
  }

}

// .ant-checkbox.ant-checkbox-checked:hover .ant-checkbox-inner {
//   border-color: var(--teal-60);
// }


