.inputBlock {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.unitColumn {
  flex: 0 0 65px;
}

.inputBlock > div {
  margin: 4px;
}

.form {
  margin: -4px;
}
