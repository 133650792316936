// first non-layout modal tag wrapper
.ant-modal-content {
  background-color: var(--new-ink-30);
  border-radius: 10px;
}

.ant-modal-header {
  padding: 24px;
  border-bottom: 0;
  background-color: transparent;
}

.ant-modal-title {
  font-size: 20px;
  line-height: 24px;
  color: var(--ink-00);
}

// close button
.ant-modal-close {
  top: 16px;
  right: 16px;
}

.ant-modal-body {
  padding: 0 24px 24px;
  border-radius: 10px;
}
