.brokenDateSelect {
  display: block;
  width: 33%;
  height: 24px;
  padding-right: 15px;

  .ant-calendar-picker-input {
    height: 32px;
    width: 100%;
    padding: 4px 4px 4px 8px;
    border-radius: 3px;
    border-color: rgba(var(--ink-00-rgb), 0.24);
    font-size: 14px;
  }

  .anticon-calendar {
    right: 8px;
    color: rgba(255, 255, 255, 0.8);
  }
}


