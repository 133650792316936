.auctionOrderForm {
  width: 100%;
  height: 100%;
  border-left: 3px solid var(--new-teal-60);
  padding-bottom: 64px;
  background-color: var(--new-ink-20);
  overflow-y: scroll;

  &.buyBorder {
    border-color: var(--new-teal-60);
  }

  &.sellBorder {
    border-color: var(--new-red-60);
  }

  .tabs {
    display: flex;

    .tab {
      position: relative;
      height: auto;
      border: none;
      background-color: inherit;
      max-width: 134px;
      padding: 14px 8px;
      line-height: 12px;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        background-color: inherit;
      }

      // active highlight bar
      &:after {
        position: absolute;
        display: none;
        bottom: 0;
        right: 0;
        left: 0;
        height: 2px;
        background-color: var(--blue-60);
        content: "";
      }
    }

    .activeTab {
      color: var(--blue-60);

      &:after {
        display: block;
      }
    }
  }

  .buySubmitButton {
    background-color: var(--new-teal-60) !important;
    color: var(--ink-90) !important;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--new-teal-50) !important;
    }

    &:active {
      background-color: var(--new-teal-40) !important;
    }
  }

  .sellSubmitButton {
    background-color: var(--new-red-60) !important;
    cursor: pointer;

    &:hover {
      background-color: var(--new-red-50) !important;
    }

    &:active {
      background-color: var(--new-red-40) !important;
    }
  }
}

.closeIcon {
  color: var(--new-ink-100);
  position: absolute;
  right: 21px;
  top: 67px;
  z-index: 1;

  svg {
    cursor: pointer;
    display: block;
    fill: var(--new-ink-100);
    margin: auto;
    opacity: 0.8;
  }
}

.labelBlock {
  width: 109px;
}

.orderMain {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 12px 0 6px 12px;
}

.inputBlock {
  display: flex;
  flex-direction: column;
  padding: 0 0 14px 4px;

  &.inputBlockDisabled {
    display: none;
  }

  > label {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    color: var(--new-ink-100);
    word-break: keep-all;
    padding-bottom: 4px;
  }

  .buy,
  .buy > div {
    color: var(--new-teal-60);
  }

  .sell,
  .sell > div {
    color: var(--new-red-60);
  }
}

.inputSection {
  width: 296px;
  font-size: 12px;
  position: relative;

  &.inputSectionNarrow {
    width: 143px;
  }

  input {
    &:invalid {
      box-shadow: 0;
    }
  }

  .timeInfo {
    position: absolute;
    left: 2px;
    font-size: 11px;
    font-style: italic;
    color: rgba(247, 247, 247, 0.8);
  }

  .timeInfoInput {
    bottom: -12px;
  }

  .timeInfoSelect {
    bottom: -17px;
  }
}

.inputDisabled {
  > div {
    background-color: transparent;
    color: var(--new-ink-40);
    border-color: var(--new-ink-40);

    &:hover {
      border-color: var(--new-ink-40);
      cursor: not-allowed;
    }
  }
}

.inputSectionSmall {
  display: flex;

  .priceRefresh {
    width: 24px;
    padding-left: 4px;
    padding-right: 0px;
    color: var(--ink-00);
    border: 0;
  }
}

.inputBlockedContainer {
  pointer-events: none;
}

// TODO: Use a more generic Prism component for this toggle.
.radioToggle {
  > label {
    background: var(--new-ink-10);
    border: 1px solid var(--new-ink-50);
    border-radius: 4px;
    color: var(--new-ink-50);
    line-height: 16px;
    margin: 0;
    padding: 5px 12px;

    &:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    > :first-child {
      display: none;
    }
  }
}

.radioDisabled {
  cursor: not-allowed;
}

// The "Buy" option is selected in Buy mode.
.buyBorder {
  .radioToggle {
    > label:first-child {
      background: var(--new-teal-60);
      color: var(--new-ink-10);
      border-color: var(--new-teal-60);
    }
  }
}

// The "Sell" option is selected in Sell mode.
.sellBorder {
  .radioToggle {
    > label:last-child {
      background: var(--new-red-60);
      border-color: var(--new-red-60);
      color: var(--new-ink-100);
    }
  }
}

.modifyPriceSection {
  width: 104px;
  height: 24px;
  display: flex;

  .priceRefresh {
    width: 24px;
    color: var(--ink-00);
    border: 0;
  }
}

.auctionActions {
  width: calc(var(--ordersPanelWidth) - 4px);
  padding: 16px;
  margin-left: -8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--new-ink-20);
  bottom: 0;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);
  gap: 9px;
  position: absolute;
  right: 0;

  button {
    height: auto;
    padding: 8px 16px;
  }

  .cancelButton {
    color: var(--new-ink-100);
  }
}

.orderStatus {
  background: var(--new-ink-20);
  border-radius: 0px 0px 10px 10px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  width: 100%;
  height: 64px;
}

.spaceBlock {
  width: 100%;
  height: 24px;
}

.enhancedOrderSettings {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 0 0 6px 12px;
}

.date {
  font-weight: normal;
}

.priceWidth {
  width: 60px;
  padding-right: 4px;
}

.currencyCode {
  color: var(--new-yellow-50);
}

.bigNum {
  font-size: 20px;
}

.headerInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 16px 7px;
  font-size: 12px;
  line-height: 16px;
  color: var(--new-ink-90);
  gap: 3px;

  .headerRow {
    display: flex;
    font-weight: 500;
    gap: 8px;

    &:first-of-type {
      .firstColumn {
        font-weight: 700;
      }
    }

    .firstColumn {
      font-weight: 400;
      width: 50%;
    }
  }
}
