@mixin blank-card-value {
  font-weight: bold;
  color: var(--ink-00);
  opacity: 0.48;
}

.priceCell {
  display: flex;
  flex: 1;
  align-content: center;
  background: var(--new-ink-20);
  border-radius: 4px;
  padding-left: 1px;
  padding-right: 1px;

  &:hover {
    background: var(--new-ink-15);
    cursor: pointer;
  }

  &:active {
    background: var(--new-ink-10);
    cursor: pointer;
  }

  &.disabled {
    background: none;
    cursor: not-allowed;
  }

  .price {
    margin: auto;
    user-select: none;
    display: flex;
    align-items: baseline;

    > span {
      font-weight: 400;
      line-height: 1;
    }
    // .left {
    //   opacity: 0.64;
    // }
    // .right {
    //   opacity: 0.64;
    // }
    .bigNum {
      font-weight: 400;
      padding: 0 1px;
    }

    // .regularNum {
    //   opacity: 0.64;
    // }

    .blank {
      @include blank-card-value;
    }
  }
}

.priceCellLg {
  height: 28px;
  margin: 0px 2px;

  .price {
    > span {
      font-size: 12px;
    }
    // .left {
    //   padding-right: 2px;
    // }
    // .right {
    //   padding-left: 2px;
    // }
    .bigNum {
      font-size: 24px;
      font-weight: 700;
    }
    .varBigSuffix {
      font-size: 12px;
      opacity: 0.48;
    }
    .blank {
      @include blank-card-value;
    }
  }
}

.priceCellSm {
  height: 20px;
  // margin: 0px 2px;

  .price {
    > span {
      font-size: 10px;
    }
    // .left {
    //   padding-right: 2px;
    // }
    // .right {
    //   padding-left: 2px;
    // }
    .bigNum {
      font-size: 14px;
      // opacity: 0.8;
    }
    .varBigSuffix {
      font-size: 10px;
      // opacity: 0.64;
    }
    .blank {
      @include blank-card-value;
    }
  }
}

.priceTradeDisabled {
  background-color: inherit;

  &:hover {
    background: inherit;
    cursor: not-allowed;
  }

  &:active {
    background: inherit;
    cursor: inherit;
  }

  &.disabled {
    background: inherit;
    cursor: not-allowed;
  }
}
