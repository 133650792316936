.statusLabel {
  &.pill {
    height: 24px;
    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    width: 96px;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 0;
    text-align: center;
  }

  &.grey {
    &.text {
      color: var(--ink-60);
    }
    &.pill {
      border-color: var(--ink-60);
    }
  }

  &.yellow {
    &.text {
      color: var(--new-yellow-50);
    }
    &.pill {
      border-color: var(--new-yellow-50);
    }
  }

  &.red {
    &.text {
      color: var(--red-60);
    }
    &.pill {
      border-color: var(--red-60);
    }
  }

  &.green {
    &.text {
      color: var(--green-60);
    }
    &.pill {
      border-color: var(--green-60);
    }
  }

  &.teal {
    &.text {
      color: var(--teal-60);
    }
    &.pill {
      border-color: var(--teal-60);
    }
  }

  &.orange {
    &.text {
      color: var(--orange-60);
    }
    &.pill {
      border-color: var(--orange-60);
    }
  }

  &.lightGrey {
    &.text {
      color: var(--ink-30);
    }
    &.pill {
      border-color: var(--ink-30);
    }
  }
}
