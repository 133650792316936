// HERE:FIX: remove body
body {
  .hotButton {
    display: flex;
    justify-content: space-between;
    width: 50%;
    background-color: var(--new-ink-20);
    font-size: 14px;

    &:first-child {
      margin-right: 2px;
    }

    &:last-child {
      margin-left: 2px;
    }

    &:not([disabled]) {
      &:hover {
        background: var(--new-ink-15);
      }

      &:active {
        background: var(--new-ink-10);
      }
    }

    .buttonLabel {
      color: var(--primary-color);
    }

    .buttonValue {
      color: var(--ink-00);
    }

    &:disabled {
      background: transparent !important;
      border: 1px solid transparent !important;

      .buttonLabel,
      .buttonValue {
        color: var(--new-ink-60);
      }
    }
  }
}
