.pageBar {
  display: flex;
  flex-flow: nowrap;
  position: relative;
}

.tabsFixedWidth {
  overflow: hidden;
}
.tabs {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  height: 100%;
}

.tabsMainHeader {
  padding-left: 0px;
}

.pageBarActions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: auto;
  position: relative;
}

.pageBarActionsDynamic {
  padding-right: 8px;
}

.leftShadow {
  position: absolute;
  top: 0px;
  left: 0%;
  width: 50px;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
}

.rightShadow {
  position: absolute;
  top: 0px;
  right: 100%;
  width: 50px;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
}

.pageInputWrap {
  width: 136px;
}

.tabLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabLabel.small {
  font-size: 12px;
  line-height: 12px;
  max-width: 134px;
  padding: 14px 0px;
}
.tabLabel.medium {
  font-size: 16px;
  line-height: 16px;
  max-width: 134px;
  padding: 16px 0px;
}
.tabLabel.large {
  font-size: 16px;
  line-height: 16px;
  max-width: 134px;
  padding: 24px 0px;
}

.standardTab.large {
  padding-right: 16px;
}
.standardTab.medium {
  padding-right: 16px;
}
.standardTab.small {
  padding-right: 8px;
}

.tab {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;

  // active highlight bar
  &::after {
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;
    left: 0;
    height: 2px;
    background-color: var(--blue-60);
    content: "";
  }

  // TEMP - pushes the menu away from the span / input content to the left
  :global .ant-dropdown-link {
    margin-left: 8px;
  }
}

.activeTab {
  color: var(--blue-60);

  &::after {
    display: block;
  }
}

.tab.large {
  padding-left: 16px;
}
.tab.medium {
  padding-left: 16px;
}
.tab.small {
  padding-left: 8px;
}
