/*
  Global variables for app-wide use
*/

:root {
  // base colors
  --ink-90: #002851;
  --ink-90-rgb: 0, 40, 81;
  --ink-80: #1e4166;
  --ink-80-rgb: 30, 65, 102;
  --ink-70: #3c5b7a;
  --ink-70-rgb: 60, 91, 122;
  --ink-60: #5a748f;
  --ink-60-rgb: 90, 116, 143;
  --ink-50: #788da3;
  --ink-50-rgb: 120, 141, 163;
  --ink-40: #b4c0cc;
  --ink-40-rgb: 180, 192, 204;
  --ink-30: #d2d9e1;
  --ink-30-rgb: 210, 217, 225;
  --ink-20: #e6ebef;
  --ink-20-rgb: 230, 235, 239;
  --ink-10: #f0f2f5;
  --ink-10-rgb: 240, 242, 245;
  --ink-00: #ffffff;
  --ink-00-rgb: 255, 255, 255;

  // color palette
  --blue-80: #046db3;
  --blue-80-rgb: 4, 109, 179;
  --blue-70: #0f8fd9;
  --blue-70-rgb: 15, 143, 217;
  --blue-60: #1fb6ff;
  --blue-60-rgb: 31, 182, 255;

  --teal-80: #059692;
  --teal-80-rgb: 5, 150, 146;
  --teal-70: #0fbdb1;
  --teal-70-rgb: 15, 189, 177;
  --teal-60: #1ee3cf;
  --teal-60-rgb: 30, 227, 207;

  --green-80: #197018;
  --green-80-rgb: 25, 112, 24;
  --green-70: #2d9627;
  --green-70-rgb: 45, 150, 39;
  --green-60: #45bc3b;
  --green-60-rgb: 69, 188, 59;

  --purple-80: #412ea3;
  --purple-70: #5d42c9;
  --purple-60: #7e5bef;

  --red-80: #b3363e;
  --red-70: #d94c51;
  --red-60: #ff6768;

  --yellow-80: #b37b0c;
  --yellow-70: #d9a01a;
  --yellow-60: #ffc82c;
  --yellow-60-rgb: 255, 200, 44;

  --orange-80: #b33c22;
  --orange-70: #d95834;
  --orange-60: #ff7849;

  // New colors taken from Figma.
  --new-ink-100: #F7F7F7;
  --new-ink-95: #F1F2F3;
  --new-ink-90: #E3E6E8;
  --new-ink-85: #D3D9DF;
  --new-ink-80: #C4CCD4;
  --new-ink-75: #B0BFCF;
  --new-ink-70: #A0B2C5;
  --new-ink-60: #7899BA;
  --new-ink-50: #4D7FB2;
  --new-ink-40: #356697;
  --new-ink-30: #224C77;
  --new-ink-25: #174069;
  --new-ink-20: #123354;
  --new-ink-15: #0B2642;
  --new-ink-10: #07192C;
  --new-ink-05: #030D17;
  --new-ink-00: #02080E;

  --new-blue-70: #7499BE;
  --new-blue-60: #33A0FF;
  --new-blue-30: #005299;
  --new-blue-20: #003666;
  --new-blue-10: #001B33;

  --new-teal-80: #A2F6E8;
  --new-teal-70: #73F2DD;
  --new-teal-60: #44EED1;
  --new-teal-50: #16E9C6;
  --new-teal-40: #11BB9E;
  --new-teal-30: #0D8C77;
  --new-teal-20: #095D4F;

  --new-green-40: #3D8F65;

  --new-red-80: #F4A4A4;
  --new-red-75: #CC7579;
  --new-red-70: #EE7777;
  --new-red-60: #E94949;
  --new-red-50: #E31C1C;
  --new-red-40: #B61616;
  --new-red-35:  #9F4848;
  --new-red-30: #881111;
  --new-red-20: #5B0B0B;
 


  --new-yellow-50: #F4CD0B;

  // theme-friendly names
  --primary-color: var(--new-blue-60);
  --font-color: var(--new-ink-100);

  // spacing and sizing
  --font-size: 16px;
  --line-height: 24px;
  --border-radius: 6px;
  --border-color: var(--new-ink-00);

  // scrollbars
  --scrollBarColor: rgba(255, 255, 255, 0.32);
  --scrollBarColorHover: rgba(255, 255, 255, 0.4);
  --scrollBarColorPress: rgba(255, 255, 255, 0.48);
  --scrollBarTrackColor: var(--new-ink-10);

  // grid panel sizes
  --ordersPanelWidth: 344px;
  // 320px;
}
