// alert banner
.alertOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--new-ink-20);
  opacity: 0.8;
  z-index: 1000; // attempt to be on top of all other potential IU elements
}

.alertContainer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
}

.alert {
  position: relative;
  z-index: 1002;
  display: flex;
  align-items: center;
  border-radius: 0 0 4px 4px;
  width: 800px;
  height: 56px;
  margin: 0 auto;
  padding: 0 16px;
  font-size: 16px;
  line-height: 24px;
  color: var(--ink-90);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24);

  .alertAction {
    margin-left: auto;
  }
}

.alertWarning {
  background: var(--yellow-60);
}

.alertError {
  background: var(--orange-60);
}

.alertContent {
  :global .ant-btn-ghost {
    border: 1px solid var(--new-ink-00);
    color: var(--ink-90);
    &:hover,
    &:active,
    &:focus {
      color: var(--ink-90);
      border-color: var(--ink-90);
    }
  }
}

// main page connecting screen
.loadingMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  .loadingText {
    padding-top: 8px;
    font-size: 16px;
  }
}

.error {
  width: 400px;
  button {
    float: right;
  }
  p {
    line-height: 24px;
    font-size: 16px;
  }
  h4 {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 8px;
  }
}

.blockingOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--new-ink-20);
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide {
  display: none;
}
