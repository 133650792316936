$connectionGood: #1ee3cf;
$connectionReconnect: #ffc82c;

.connectionIndicator {
  display: inline-flex;
  align-items: center;
  z-index: 98;
}

.iconConnection {
  overflow: hidden;
  position: relative;
  width: 40px;
  height: 40px;
}

.iconConnection:after {
  border-radius: 6px;
  bottom: 0;
  content: "";
  height: 12px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 12px;
  z-index: 8;
}

.iconConnection:before {
  border-radius: 6px;
  bottom: 0;
  content: "";
  height: 12px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 12px;
}

.iconConnectionGood:after,
.iconConnectionGood:before {
  background: $connectionGood;
}

.iconConnectionGood:before {
  animation: pulse-connected 4s infinite;
}

.iconConnectionReconnect:after,
.iconConnectionReconnect:before {
  background: $connectionReconnect;
}

.iconConnectionReconnect:before {
  animation: pulse-connected 1s infinite;
}

@keyframes pulse-connected {
  0% {
    opacity: 0.48;
    transform: scale3d(0.8, 0.8, 1);
  }
  33% {
    opacity: 0.48;
    transform: scale3d(0.8, 0.8, 1);
  }
  100% {
    opacity: 0;
    transform: scale3d(3, 3, 1);
  }
}
