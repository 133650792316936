/*
  Select - typical form select field. AntD has a lot of extra use cases that we haven't ran into
  so don't be surprised if something 'edge' isn't styled out yet. The select dropdown itself is classnamed
  version of the base Dropdown

  Usage:
    https://ant.design/components/select/#header

  Design:
    https://www.figma.com/file/eeFxpoSGBRLqDDIAtRMTOhCO/Component-Library?node-id=284%3A31
    https://www.figma.com/file/bDra0jQUuM6KqDA2CPNIvU7e/Documentation?node-id=78%3A1
*/

@at-root {
  .ant-select .ant-select-selection {
    box-shadow: none;
  }

  .ant-select-selection {
    display: flex;
    align-items: center;
    height: 28px;
    border-color: var(--new-ink-50);
    vertical-align: middle;
    background-color: var(--new-ink-10);

    .ant-select-selection__rendered {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }

    &:hover,
    &:focus,
    &:active {
      border-color: var(--new-ink-100);
    }
  }

  .ant-select {
    width: 100%;
    &.ant-select-sm .ant-select-selection-selected-value {
      font-size: 12px;
      line-height: 24px;
      padding: 4px 0px;
    }

    &.ant-select-sm .ant-select-selection {
      height: 24px;
    }

    &.ant-select-lg {
      .ant-select-selection {
        height: 32px;
      }
    }
  }

  .ant-select-lg {
    font-size: 16px;
    font-weight: 400;
  }

  .ant-select-arrow {
    color: var(--ink-00);
  }

  // Select menu is portaled outside of the select itself, so we can't chain classes/
  .ant-select-dropdown-menu {
    border-radius: 4px; // since theres padding, we can round the parent
    padding: 4px 0;
    background-color: var(--new-ink-30);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);

    .ant-select-dropdown-menu-item {
      &:hover {
        background-color: var(--new-ink-20);
      }

      &:active {
        background-color: var(--new-ink-20);
        outline: none;
      }
    }
  }

  .ant-select-dropdown-menu-item {
    position: relative;
    height: 40px;
    background-color: transparent;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;

    // turn off antd corners since they would show up after the parent padding
    &:first-child,
    &:last-child {
      border-radius: 0;
    }

    // blue dot for selected items - same as in Dropdown, recreated due to a lack of shared classes
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 6px;
      display: none;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin: auto 0;
      content: "";
      background-color: var(--blue-60);
    }

    &.ant-select-dropdown-menu-item-active {
      background-color: transparent;
    }

    &.ant-select-dropdown-menu-item-selected {
      color: var(--ink-00);
      font-weight: normal;
      background-color: transparent;

      &::after {
        display: block;
      }
    }
  }

  .ant-select-disabled .ant-select-selection {
    background-color: inherit;

    &:hover,
    &:active,
    &:focus {
      border-color: var(--new-ink-40);
      color: inherit;
    }
  }
}
