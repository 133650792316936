.currencyPairItem {
  padding: 8px 0px;
  opacity: 0.8;
  cursor: pointer;
}
.itemUnchecked {
  visibility: hidden;
}

.bullet {
  padding-right: 5px;
  color: var(--blue-60);
}

.modal {
  :global .ant-modal-body {
    background-color: var(--ink-90);
    padding: 0px;
    border-radius: 2px;
  }
}

.list {
  padding: 8px 16px 0px 16px;
  height: 332px;
  overflow: scroll;
}

////////

.header {
  padding: 8px 16px 0px 16px;
  border-bottom: 1px solid var(--new-ink-00);
  .headerItem {
    margin: 8px 0px;
    opacity: 0.64;
  }
}

.footerElement {
  margin-left: 8px;
  min-width: 64px;
}

.footer {
  padding: 8px 16px;
  border-top: 1px solid var(--new-ink-00);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
