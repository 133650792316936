/*
  We support the following buttons:
  - Primary
  - Default
  - Ghost
  - Icon

  Usage examples:
    https://ant.design/components/button/#header

  Design
    https://www.figma.com/file/eeFxpoSGBRLqDDIAtRMTOhCO/Component-Library?node-id=204%3A412
*/

@at-root {
  .ant-btn {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-color: var(--new-ink-50);
    border-radius: 3px;
    background: transparent;
    padding-right: 16px;
    padding-left: 16px;
    border-width: 1px;
    border-style: solid;
    color: var(--ink-00);
    font-size: 16px;
    line-height: 1px;
    box-shadow: none;

    // default style, no background, white border
    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        border-color: var(--new-ink-100);
        color: var(--new-ink-100);
        // background: inherit;
      }
    }

    &:disabled {
      background: transparent;
      color: var(--new-ink-40);
      border-color: transparent;
    }

    // default button dark theme
    &.themeDark {
      border-color: var(--new-ink-50);
      color: var(--ink-90);

      &:hover,
      &:focus {
        border-color: rgba(var(--ink-90-rgb), 0.64);
        color: var(--ink-90);
      }
      &:active {
        border-color: var(--new-ink-00);
        color: var(--ink-90);
      }
    }

    // dropping the 'as text' styling for button icons because they induce rage
    .anticon {
      display: inline-block;
      font-size: 16px;
      line-height: 1;
      vertical-align: unset; // inner svg is now block

      > svg {
        display: block;
      }
    }

    // antd sometimes adds spans inside of buttons. most commonly for 'with icon'
    // cases, but sometimes just to do it too....
    span {
      line-height: 1;
    }
  }

  // sizes
  .ant-btn-lg {
    height: 40px;
  }

  .ant-btn-sm {
    height: 24px;
    padding-right: 4px;
    padding-left: 4px;
  }

  // primary style, no border, blue background
  .ant-btn.ant-btn-primary {
    border: 0;
    background: var(--blue-60);
    color: var(--ink-100);

    &:hover,
    &:focus {
      background: var(--blue-70);
    }
    &:active {
      background: var(--blue-80);
    }

    &:disabled {
      background: var(--new-ink-10); // exception
      color: var(--new-ink-40);
    }
  }

  // icon only, no text
  .ant-btn.ant-btn-icon-only {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    padding: 0;
    color: var(--ink-00);

    &:hover,
    &:focus {
      background: var(--new-ink-30);
    }
    &:active {
      background: var(--new-ink-40);
    }

    &:disabled {
      background: transparent; // exception
      color: var(--new-ink-40);
    }

    svg {
      display: block;
    }

    &.ant-btn-lg .anticon {
      font-size: 20px;
    }
  }

  // ghost button, text only, no BG
  .ant-btn.ant-btn-ghost {
    color: var(--primary-color);
    border: 0;
    background: transparent;

    &:hover,
    &:focus {
      background: var(--new-ink-30);
    }
    &:active {
      background: var(--new-ink-40);
    }

    // exception
    &:disabled {
      background: var(--new-ink-10);
      color: var(--new-ink-40);
    }
  }

  /*
    Close Button

    Close buttons have slightly different styling, and unfortunately, aren't
    classnamed like typical buttons, so we handle them separately here.

    Note that we don't declare anything about layout or positioning here. That should
    be up to the component that has a clase button (e.g. modal)
  */
  // modal
  .ant-modal-close-x,
  // settings panel
  .ant-drawer-close {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(var(--ink-00-rgb), 0.08);
    line-height: 40px;
    color: var(--ink-00);
    transition: background-color 0.15s linear;

    &:hover,
    &:focus {
      background-color: var(--new-ink-30);
    }

    &:active {
      background-color: var(--new-ink-40);
    }
  }

  [ant-click-animating-without-extra-node='true']::after{
    display:none;
  }

}
