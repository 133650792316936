.header {
  display: flex;
  border-bottom: var(--new-ink-00) 1px solid;
  width: 100%;
  height: 64px;
  min-width: 0px;
  overflow: hidden;

  .headerActions {
    display: flex;
    flex-flow: row nowrap;
    padding-right: 24px;
    align-items: center;

    .lockActive {
      color: var(--blue-60);
    }

    // .lockInactive {
    //   color: red;
    // }
  }
}

.logo {
  display: flex;
  justify-content: center;
  width: auto;
  height: 48px;
  background-color: transparent;
  margin: 8px 8px 8px 16px;
  border-radius: 4px;
  flex-shrink: 0;

  .logoImage {
    width: inherit;
  }

  .img {
    background-color: var(--ink-00);
  }
}

.headerPageBar {
  flex-grow: 1;
  overflow: hidden;
}

.tagline {
  font-size: 12px;
  margin: auto;
  padding-right: 24px;
  color: var(--ink-40);
  line-height: 16px;
  width: 132px;
}

.taglineHidden {
  display: none;
}

.addPairButton {
  padding-right: 8px;

  button:not([disabled]) {
    border-color: var(--new-ink-100);

    &:hover {
      background: var(--new-ink-30);
    }

    &:active {
      background: var(--new-ink-40);
    }
  }
}

// Contact Support modal

.contactModal {
  padding: 16px;

  div {
    // width: 100%;
    color: var(--new-ink-100);
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    padding-bottom: 24px;
  }

  .smallTitle {
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 4px;
  }

  .titleValue {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
  }

  .closeButton {
    button {
      position: absolute;
      bottom: 16px;
      right: 16px;
      width: 76px;
      height: 32px;

      background: var(--blue-60);
      border-radius: 3px;
    }
  }
}
