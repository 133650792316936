.buy {
  color: var(--new-teal-60);
}

.sell {
  color: var(--new-red-60);
}

.customHeaderValue {
  color: var(--ink-00);
  font-weight: 600;
}

.currencyDot {
  height: 4px;
  width: 4px;
  background-color: var(--ink-00);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  bottom: 2px;
}

.dotBox {
  width: 4px;
  height: 100%;
  margin-left: 4px;
}

.instrumentBox {
  display: flex;
}

body .actionsContainer{
  position: absolute;
  top: 20px;
  display: flex;

  .action{
    height: 24px;
    padding: 0 10px;
    border: none;
    font-size: 14px;
    color: var(--new-ink-100);

    &:first-child{
      padding-left: 21px;
    }

    &:hover {
      color: var(--blue-60);
      background-color: inherit;
    }

    &:disabled{
      background: none;
      // color: var(--new-ink-40);
      color: rgba(255, 255, 255, 0.48);

      &:hover{
        // color: rgba(var(--ink-00-rgb), 0.48);
        background: inherit;
        border: none;
        // color: var(--new-ink-40);
      }
    }

  }

}
