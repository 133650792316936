@at-root {
  body {
    background-color: var(--new-ink-20);
  }

  /*
    Typography Headings

    Design:
      https://www.figma.com/file/bDra0jQUuM6KqDA2CPNIvU7e/Documentation?node-id=30%3A0
  */

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0;
  }

  // color tints

  h1,
  h2,
  h3,
  h4 {
    color: var(--new-ink-100);
  }

  // sizes
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  // special header
  h5 {
    font-size: 12px;
    color: rgba(var(--ink-00-rgb), 0.64);
    letter-spacing: 0.03rem;
    text-transform: uppercase;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
