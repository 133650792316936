.settings {
  padding-bottom: 56px;
}

.settingsTabs {
  height: 100%;
  overflow: visible !important; // Override AntD.
}

.formBlock {
  padding-bottom: 24px;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  h5 {
    padding-bottom: 16px;
  }
}

.inputBlock {
  padding-bottom: 8px;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  label {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
  }
}

.orderStatuses {
  .inputBlock {
    padding-bottom: 0px;
    margin-bottom: 0px;
    height: 56px;
  }
}

.switchBlock {
  display: flex;
  padding-bottom: 8px;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  > label {
    flex: 1;
  }
}

.footer {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--new-ink-00);
  background-color: var(--new-ink-30);
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding: 8px 16px;

  :global .ant-btn {
    margin-left: 8px;
  }
}

.version {
  bottom: 120px;
  color: rgba(var(--ink-00-rgb), 0.64);
  font-size: 12px;
  left: 20px;
  position: absolute;
}

.tabsLeftSaveBtn {
  position: absolute;
  margin-top: auto;
  bottom: 16px;
  left: 16px;
  width: 168px;
}

.saveButton:disabled {
  background: rgba(var(--blue-80-rgb), 0.32) !important;
}

.tabsLeftSaveBtn > button {
  width: 100%;
}

.closeButton {
  margin-top: 8px;
}

.drawerTitle {
  background-image: linear-gradient(
    var(--ink-90), var(--ink-90), // 60px
    rgba(var(--ink-90-rgb), 0) // 30px
  );
  height: 90px;
  left: 0;
  padding: 16px;
  position: absolute;
  top: 0;
  width: 200px;
  z-index: 2;
}

.drawerFooter {
  background-image: linear-gradient(
    rgba(var(--ink-90-rgb), 0), // 30px
    var(--ink-90), var(--ink-90), var(--ink-90), var(--ink-90), var(--ink-90) // 150px
  );
  bottom: 0;
  height: 180px;
  left: 0;
  position: absolute;
  width: 200px;
  z-index: 2;
}
