.currencyPairItem {
  padding: 8px 0px;
  opacity: 0.8;
  cursor: pointer;
}
.itemUnchecked {
  visibility: hidden;
}

.bullet {
  padding-right: 5px;
  color: var(--blue-60);
}

.list {
  padding: 8px 16px 0px 16px;
  overflow: scroll;
  overflow-x: hidden;
  max-width: 100%;
  background-color: var(--new-ink-20);
}

.noMatchDiv {
  position: relative;
  width: 90%;
  text-align: center;
  color: #ffffff;
  opacity: 0.64;
  margin: 0 auto;
  padding-top: 56px;
}
