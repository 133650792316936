.radio {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  // height: 24px;
  // padding: 0 8px;
  border: 0;
  border-radius: 4px;
  background-color: rgba(var(--ink-70-rgb), 0.64);
  // font-size: 12px;
  // line-height: 1;
  color: var(--teal-60);
  outline: none;
  white-space: nowrap;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: rgba(var(--ink-70-rgb), 0.8);
  }

  &:active {
    background-color: var(--ink-70);
  }

  &.radioActive {
    box-shadow: 0 0 0 2px var(--teal-60);
  }

  &.radioBlock {
    width: 100%;
  }

  &.lg {
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 1;
  }

  &.md {
    height: 32px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 1;
  }

  &.sm {
    height: 24px;
    padding: 0 8px;
    font-size: 12px;
    line-height: 1;
  }
}
