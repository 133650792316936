body {
  .flywheelMenu {
    position: absolute;
    top: -16px;
    left: 0;
    z-index: 2;
    // max-height: 338px;
    background-color: var(--new-ink-10);
    background-clip: padding-box;
    border-radius: 4px;
    // padding: 4px 0;
    margin: 0;
    text-align: left;
    outline: none;
    user-select: none;

    // overflow-y: scroll;
    //     scrollbar-width: none; /* Firefox */
    //     -ms-overflow-style: none;  /* Internet Explorer 10+ */
    // &::-webkit-scrollbar {
    //   /* WebKit */
    //   width: 0;
    //   height: 0;
    // }

    .flywheelMenuItem {
      background-color: transparent;
      // font-size: 14px;
      font-size: 12px;
      height: 26px;
      height: auto;
      line-height: 1;
      margin: 0 !important;
      // padding: 6px 9px;
      // HERE: Figma design
      // padding: 8px 13px 8px 26px;
      padding: 2.5px 15px 2.5px 15px;
      text-align: center;

      &:hover {
        background-color: var(--new-ink-20);
      }

      &Title {
        padding: 8px 10px;
        font-weight: 700;
        color: var(--new-ink-100);
        cursor: initial;

        &.buyColor {
          background-color: var(--new-green-40);
        }

        &.sellColor {
          background-color: var(--new-red-35);
        }

        &:hover {
          color: var(--new-ink-100);
        }
      }

      &Arrow {
        height: 19px;
        padding: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 11px;
        line-height: 1;
        color: var(--new-ink-100);

        &:hover {
          color: var(--new-ink-100);
        }
      }

      .specialNumbersContainer {
        display: inline;
      }

      .basePrice {
        color: var(--new-ink-100);
      }

      .smallNumber {
        // font-size: 10px;
        font-size: 12px;
        color: var(--new-ink-100);
      }

      .bigNumber {
        margin: 0 2px;
        // HERE: Figma design
        // font-size: 22px;
        font-size: 21px;
        font-weight: 500;
        color: var(--new-ink-100);
      }

      .highlightedItem {
        color: var(--new-red-75);

        .bigNumber,
        .smallNumber {
          color: var(--new-red-75);
        }
      }

      .lowlightedItem {
        color: var(--new-blue-70);

        .bigNumber,
        .smallNumber {
          color: var(--new-blue-70);
        }
      }
    }

    .flywheelMenuItemNoHighlights {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  .flywheelCardBuyPosition {
    transform: translatex(-100%) translateY(-54%) translateY(24px);
  }

  .flywheelCardSellPosition {
    right: 0;
    left: auto;
    transform: translateX(100%) translateX(-4px) translateY(-54%) translateY(24px);
  }

  .flywheelOrderPosition {
    transform: translateX(-114px) translateY(27px);
    left: inherit;
    right: 26px;

    .flywheelMenuItem {
      // transform: scaleX(-1);
    }
  }
}
