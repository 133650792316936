/*
  Input = handles normal and number inputs

  Usage:
    https://ant.design/components/input/#header
    https://ant.design/components/input-number/#header

  Design:
    https://www.figma.com/file/bDra0jQUuM6KqDA2CPNIvU7e/Documentation?node-id=308%3A0
*/

@at-root {
  .ant-input,
  .ant-input-number {
    width: 100%;
    height: 28px;
    border-color: var(--new-ink-50);
    background-color: var(--new-ink-10);
    font-size: 16px;

    &.ant-input-number-sm {
      height: 24px;
      font-size: 12px;
      padding-left: 0px;
    }

    &.ant-input-number-lg {
      height: 32px;
      font-size: 16px;
      font-weight: 400;
      padding-left: 0px;
    }

    &:hover,
    &:focus,
    &:active {
      border-color: var(--new-ink-100);
    }

    &:disabled {
      // TODO: Hover/etc. styling to overrid AntD. Remove once we implement our styles fully.
      &,
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        border-color: var(--new-ink-40);
        color: var(--new-ink-40);
      }
    }
  }

  .ant-input.ant-input-sm {
    height: 24px;
    padding: 1px 7px;
  }

  .ant-input {
    height: 28px;
  }

  .ant-input.ant-input-lg {
    height: 32px;
  }

  .ant-input-number-disabled {
    // TODO: Hover/etc. styling to overrid AntD. Remove once we implement our styles fully.
    &,
    &:hover,
    &:focus,
    &:active {
      border-color: var(--new-ink-40);
      background-color: transparent;
      color: var(--new-ink-40);
    }
  }

  .ant-input-number {
    // &:hover,
    // &:focus {
    //   border-color: rgba(var(--ink-00-rgb), 0.48);
    // }

    // &:active {
    //   border-color: rgba(var(--ink-00-rgb), 0.64);
    // }

    .ant-input-number-input {
      height: 16px;
      position: absolute;
      // padding-left: 12px;
      top: calc(50% - 16px / 2);
      line-height: 24px;
    }

    &.ant-input-number-focused {
      border-color: var(--ink-fade-64);
    }
    // this tag contains the 'up/down' arrows for stepping through the numbers. Theres no way (that I can tell)
    // to turn them off in React so we just hide them here.
    .ant-input-number-handler-wrap {
      display: none;
      pointer-events: none;
    }
  }
}
