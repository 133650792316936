.ordersList {
  overflow: hidden;
}
.order-animate {
  position: relative;
}
.order-animate-appear,
.order-animate-enter {
  left: -100%;
}
.order-animate-appear-active,
.order-animate-enter-active {
  left: 0;
  transition: left 400ms;
  transition-timing-function: ease-out;
}

.order-animate-exit {
  left: 0;
}
.order-animate-exit-active {
  left: 100%;
  transition: left 400ms;
  transition-timing-function: ease-out;
}
