// $cardWidth: 216px; // also set in currencyGrid.tsx
// $cardWidthPlusMargin: 224px;
$cardWidth: 271px; // also set in currencyGrid.tsx
$cardWidthPlusMargin: 279px;

$cardMediumWidth: 338px; // also set in currencyGrid.tsx
$cardMediumWidthPlusMargin: 346px;

$cardLargeWidth: 381px; // also set in currencyGrid.tsx
$cardLargeWidthPlusMargin: 389px;

$cardHeight: 302px;
$modalWidth: 392px + 4px; // modal dimensions also set in Card.tsx, 4px is border
$modalHeight: 378px + 4px;

$inputSmallFontSize: 14px;
$inputMediumFontSize: 16px;
$inputLargeFontSize: 18px;


@mixin blank-card-value {
  font-weight: bold;
  color: var(--ink-00);
  opacity: 0.64;
}

.currencyGrid {
  width: 100%;
  display: flex;
  padding: 8px;
  align-items: flex-start;
  justify-content: center;
}

.currencyCards0 {
  justify-content: center;
  position: relative;
  width: 100%;
}
.currencyCards3 {
  justify-content: flex-start;
}
.currencyCardsAll {
  align-items: flex-start;
  padding: 16px;
}

.currencyCards {
  /* This is the magic stuff that you need to get our
     left-aligned card-alignment and auto-centering grid. */
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, $cardWidthPlusMargin);
  max-width: calc(100vw - 210px - var(--ordersPanelWidth)); // Allow room for the Flywheel.

  /* Completely un-magical stuff here. */
  width: auto;

  .background-image {
    margin-bottom: 24px;
  }

  .noCard {
    text-align: center;
    margin: auto;
    margin-top: 48px;

    .noCardTitle {
      color: var(--new-ink-100);
    }

    .noCardBtn {
      margin: auto;
      margin-top: 17px;
      background: var(--blue-60);
      border-color: var(--blue-60);
    }
  }
}

.currencyCardsMaxWidth {
  max-width: calc(100vw);
}

.cardDragContainer {
  position: relative;
  border-radius: 8px;
}

.roundedBorder {
  border-radius: 8px;
}

.dropdownWrapper {
  margin: -1px -4px 0 0;
}

.card {
  background: var(--new-ink-30);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  flex-wrap: wrap;
  font-weight: 400;
  margin: 4px;

  &--buy {
    border: 2px solid var(--new-teal-60);
    margin: 2px;
  }

  &--sell {
    border: 2px solid var(--new-red-60);
    margin: 2px;
  }

  .selectorWrapper {
    z-index: 200;
    position: relative;

    .currencyPairSelector {
      width: 206px;
      // height: 200px;
      position: absolute;
      top: 24px;
      left: 5px;
      opacity: 1;
      background: var(--new-ink-40);
      border-radius: 4px;
      box-shadow: 0 2 8px rgba(0, 0, 0, 0.15);
    }
  }

  .currencyMeta {
    padding: 4px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .actions {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    position: relative;

    button {
      text-align: center;
      width: 48px;
      font-size: $inputSmallFontSize;

      &:disabled {
        background: transparent !important;
        color: var(--new-ink-60);
      }
    }

    .quantityInput {
      width: 96px;
      text-align: center;
      background: var(--new-ink-30);
      font-size: $inputSmallFontSize;
      line-height: 16px;

      &:enabled:hover {
        background: var(--new-ink-20);
        color: var(--new-ink-100);
      }

      &:disabled:hover {
        color: var(--new-ink-50);
      }

      &.ant-input-sm {
        height: 24px;
      }
    }

    .currencySelect {
      margin-left: 0;
      max-width: 54px;
      .ant-select-selection__rendered {
        margin-left: 0;
      }
      .ant-select-selection .ant-select-arrow,
      i {
        font-size: 10px;
        width: 10px;
        padding-top: 1px;
      }
    }
    .ant-input-number.quantityInput,
    .ant-input-number.quantityInput .ant-input-number-input,
    .ant-select > .ant-select-selection {
      padding: 0 2px 0 4px;
    }
    .ant-select .ant-select-selection,
    .ant-select-selection__rendered,
    .ant-select-selection-selected-value {
      height: 24px;
      line-height: 16px;
    }
    .ant-select > .ant-select-selection .ant-select-arrow {
      right: 4px;
      margin-top: -6px;
    }
    .ant-select-selection-selected-value {
      padding: 4px 0;
    }
  }
}

.cardHeader {
  align-items: self-start;
  display: flex;
  font-size: 12px;
  height: 38px;
  line-height: 16px;

  .currency {
    flex-grow: 1;
    font-weight: bold;
    padding: 2px 0 0 2px;
    width: 94px;

    .currencyButton {
      display: flex;
      height: 17px;
      min-width: 84px;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: 700;
    }

    .currencyInstrumentLabel {
      font-weight: 700;
      white-space: nowrap;
    }
  }

  .currencyCode {
    color: var(--new-yellow-50);
  }

  .date {
    color: var(--new-ink-100);
    padding: 2px 5px 0 0;
    font-size: 10px;
  }

  button {
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .close-icon {
    color: var(--new-ink-100);

    svg {
      display: block;
      fill: var(--new-ink-100);
      opacity: 0.8;
      margin: auto;
      cursor: pointer;
    }
  }

  .cardOverflowButton {
    color: var(--new-ink-100);
    width: 24px;
  }
}

.dropDownSection {
  position: relative;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.dropdownContent {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 158px;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;

  background-clip: padding-box;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  &:focus {
    outline: none;
  }

  .cardOverflow {
    border-radius: 4px;
    height: 100%;
    background-color: var(--new-ink-40);
    padding: 4px 0;

    .ant-menu-item {
      height: 40px;
      background-color: transparent;
      padding: 8px 16px;
      font-size: 16px;
      line-height: 24px;
      min-width: 74px;
      margin: 0;
      margin-bottom: 0 !important; // needed to overwrite .ant-menu-vertical .ant-menu-item:not(:last-child)

      &:first-child {
        height: 96px;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        &:hover {
          background-color: transparent;
        }
      }

      &.ant-menu-item-active {
        background-color: var(--new-ink-20);
        color: var(--new-ink-100);
      }
    }

    .ant-radio-group {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .ant-menu-item-divider {
      background-color: rgba(var(--ink-00-rgb), 0.12);
      margin: 4px 0;
    }

    .ant-select-dropdown-menu-item {
      &.ant-radio-wrapper-disabled {
        background: var(--new-ink-40) !important;
      }

      &:not(.ant-radio-wrapper-disabled) {
        &:hover {
          background: var(--new-ink-20) !important;
        }
      }

      &.ant-radio-disabled-option {
        cursor: not-allowed;

        > span {
          color: var(--new-ink-60);
        }
      }

      &.ant-select-dropdown-menu-item-selected {
        > span {
          color: var(--new-ink-100);
        }
      }

      &,
      .ant-radio-wrapper {
        margin-right: 0;
      }
      // &:active {
      //   background-color: var(--new-ink-20);
      // }

      > span {
        padding-left: 0;
      }
    }

    // .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    //   background-color: var(--new-ink-20);
    // }

    .ant-radio-inner {
      display: none;
    }

    .currencySelector {
      display: block;
      height: 30px;
      line-height: 30px;
    }
  }
}

.priceInfo {
  display: flex;
  width: 100%;
  height: 24px;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;

  .spread {
    font-size: 14px;
    font-weight: 600;
    max-width: 70px;
    text-align: center;
  }

  .side {
    display: flex;
    font-size: 10px;
    font-weight: bold;
    align-items: center;

    .leftLabel {
      // width: 56px;
      // opacity: 0.48;
      margin-left: 5px;
    }

    .rightLabel {
      // width: 56px;
      // opacity: 0.48;
      text-align: right;
      margin-right: 5px;
    }

    .indicatorContainer {
      min-width: 14px;
      padding-top: 6px;
      padding-bottom: 6px;

      &.left {
        margin-left: 3px;
      }

      &.right {
        margin-right: 3px;
      }
    }
  }
}

.priceRow {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 29px;
}

.priceContainer {
  width: 72px;

  &.left {
    text-align: right;
  }

  &.right {
    text-align: left;
  }
}

.volumeCol {
  width: 40px;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}

.numberSm {
  font-size: 10px;
  // opacity: 0.48;
}

.numberLg {
  font-size: 16px;
  font-weight: bold;
  // opacity: 0.64;
  padding: 0 2px;
}

.priceTable {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  padding: 0 7px;

  .priceTableRow {
    border-top: 1px solid var(--new-ink-20);
    display: flex;
    flex-direction: column;
    width: 100%;

    .rowData {
      height: 32px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    .rowWrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
    }
  }

  .priceTableRowSpreadValue {
    width: auto;
    font-size: 10px;
    color: var(--new-ink-100);
    text-align: center;
    align-items: baseline;
    height: 16px;
    width: 39px;
  }

  td {
    vertical-align: middle;
  }
  .blank {
    @include blank-card-value;
  }
}

.dragZone {
  box-shadow: none;
  opacity: 1;
}

.dragZone > .card {
  transform: rotate(1deg);
  opacity: 1;
  background-color: var(--new-ink-30);
  border-radius: 8px;
}

.ghostZone {
  background: var(--new-ink-30);
}

.ghostZone > .card {
  opacity: 0;
}

.slippage {
  // the following !importants are because I need overrides specific to the card slippage section
  display: flex;
  padding: 4px 3px 0;
  width: 100%;
  align-items: flex-start;

  &Dropdown {
    width: 80px;
    top: 14px;
    right: -8px;

    .cardOverflow {
      .ant-menu-item {
        padding-top: 0;
        padding-bottom: 0;
        height: 78px;
      }
    }

    .ant-select-dropdown-menu-item {
      font-size: $inputSmallFontSize;
    }
  }

  &Button {
    min-width: 68px;
    margin-right: 4px;
    font-size: $inputSmallFontSize;
    justify-content: space-between;

    &:not([disabled]) {
      background: var(--new-ink-20);
      color: var(--new-ink-100);

      &:hover,
      &:focus {
        background: var(--new-ink-15);
      }

      &:active {
        background: var(--new-ink-10);
      }
    }

    .selectedCurrency {
      color: var(--new-ink-100);
    }
  }

  .ant-input-number {
    background: var(--new-ink-20);

    &:hover {
      background: var(--new-ink-15);
    }
  }

  .ant-input-number-sm {
    font-size: 14px !important;
    // width: 64px;
  }

  > .ant-select-sm {
    width: 72px;
  }

  .ant-select.ant-select-sm .ant-select-selection-selected-value {
    font-size: 14px !important;
  }

  .ant-select-arrow {
    right: 4px !important;
  }

  .ant-input-number-input {
    font-size: $inputSmallFontSize;
  }
}

.slippageIncrementer {
  margin-left: 4px;
  padding: 0 3px;

  &:not([disabled]) {
    background: var(--new-ink-20) !important;

    &:hover,
    &:focus {
      background: var(--new-ink-15) !important;
    }

    &:active {
      background: var(--new-ink-10) !important;
    }
  }
}

// Override AntD styling of disabled fields.
.ant-radio-wrapper-disabled {
  .ant-radio-disabled {
    + span {
      color: var(--new-ink-40);
    }
  }
}

.hotButtonRow {
  display: flex;
  padding: 4px 7px;
}

.tooltip {
  display: none;
  margin-left: 10px;
  position: absolute;
  width: 0;
  z-index: 1;

  &::after {
    background-color: #000000;
    content: "";
    display: block;
    height: 15px;
    left: -4px;
    position: relative;
    top: 6px;
    transform: rotate(45deg);
    width: 15px;
  }

  &TextContainer {
    background-color: #000000;
    border-radius: 3px;
    color: var(--ink-00);
    font-size: 14px;
    padding: 3px 8px;
    position: absolute;
    white-space: nowrap;
    z-index: 2;
  }

  &Wrapper {
    position: relative;
    white-space: nowrap;

    :first-child:hover {
      + .tooltip {
        display: inline-block;
      }
    }

    &--wide {
      width: 100%;
    }
  }
}

.currencyCardsMediumSize {
  // This is the magic stuff that you need to get our left-aligned card-alignment and auto-centering grid.
  grid-template-columns: repeat(auto-fit, $cardMediumWidthPlusMargin);

  .card {
    width: $cardMediumWidth;

    .cardHeader {
      font-size: 14px;
    }

    .currencyButton {
      font-size: 16px;
    }

    .priceInfo {
      .side {
        font-size: 12px;
      }

      .spread {
        font-size: 16px;
        max-width: 100px;
      }
    }

    .actions {
      button {
        font-size: $inputMediumFontSize;
      }

      .quantityInput {
        font-size: $inputMediumFontSize;
        width: 110px;
      }
    }

    .slippage {
      .slippageButton,
      .slippageDropdown .ant-select-dropdown-menu-item,
      .ant-input-number-input {
        font-size: $inputMediumFontSize;
      }
    }

    .slippageIncrementer {
      padding: 0 5px;
    }

    // TODO: change, use variable
    .slippageButton,
    .slippageIncrementer,
    .ant-input-number,
    .bid,
    .quantityInput.ant-input-sm,
    .offer {
      height: 28px;
    }

    .hotButton {
      font-size: $inputMediumFontSize;
      height: 28px;
    }

    .priceTableRowSpreadValue {
      font-size: 12px;
      height: 20px;
      width: 46px;
    }
  }

  .priceRow {
    height: 33px;
  }

  .priceContainer {
    width: 85px;
  }

  .volumeCol {
    width: 53px;
  }

  // PriceButton
  .priceCellLg {
    height: 32px;

    .price {
      > span {
        font-size: 14px;
      }

      .bigNum {
        font-size: 28px;
      }
    }
  }

  .priceCellSm {
    height: 24px;

    .price {
      > span {
        font-size: 12px;
      }

      .bigNum {
        font-size: 16px;
      }
    }
  }

  // SizeButton
  .numberSm .size {
    font-size: 12px;
    line-height: 20px;
  }
  .numberLg .size {
    font-size: 18px;
    line-height: 26px;
  }

  .numberSm {
    font-size: 12px;
  }

  // .flywheelMenu .flywheelMenuItem {
  //   font-size: $inputMediumFontSize;
  //   line-height: $inputMediumFontSize;
  // }
}

.currencyCardsLargeSize {
  // This is the magic stuff that you need to get our left-aligned card-alignment and auto-centering grid.
  grid-template-columns: repeat(auto-fit, $cardLargeWidthPlusMargin);

  .card {
    width: $cardLargeWidth;

    .cardHeader {
      font-size: 14px;
    }

    .currencyButton {
      font-size: 16px;
      display: inline-block;
      min-width: auto;
    }

    .priceInfo {
      .side {
        font-size: 14px;
      }

      .spread {
        font-size: 18px;
        max-width: 120px;
      }
    }

    .actions {
      button {
        font-size: $inputLargeFontSize;
      }

      .quantityInput {
        font-size: $inputLargeFontSize;
        width: 124px;
      }
    }

    .slippage {
      .slippageButton,
      .slippageDropdown .ant-select-dropdown-menu-item,
      .ant-input-number-input {
        font-size: $inputLargeFontSize;
      }
    }

    .slippageIncrementer {
      padding: 0 7px;
    }

    // TODO: change, use variable
    .slippageButton,
    .slippageIncrementer,
    .ant-input-number,
    .bid,
    .quantityInput.ant-input-sm,
    .offer {
      height: 32px;
    }

    .hotButton {
      font-size: $inputLargeFontSize;
      height: 32px;
    }

    .priceTableRowSpreadValue {
      font-size: 14px;
      height: 25px;
      width: 55px;
    }
  }

  .priceRow {
    height: 37px;
  }

  .priceContainer {
    width: 100px;
  }

  .volumeCol {
    width: 55px;
  }

  // PriceButton
  .priceCellLg {
    height: 36px;

    .price {
      > span {
        font-size: 16px;
      }

      .bigNum {
        font-size: 32px;
      }
    }
  }

  .priceCellSm {
    height: 28px;

    .price {
      > span {
        font-size: 14px;
      }

      .bigNum {
        font-size: 18px;
      }
    }
  }

  // SizeButton
  .numberSm .size {
    font-size: 14px;
    line-height: 20px;
  }
  .numberLg .size {
    font-size: 20px;
    line-height: 26px;
  }

  .numberSm {
    font-size: 14px;
  }

}

body .cardModal {

  .ant-modal-content{
    width: 336px;
    border-radius: 9px;
  }

  .ant-modal-body {

    padding: 24px;
    border: 2px solid var(--new-teal-60);

    .close{
      position: absolute;
      top: 17px;
      right: 17px;
      cursor: pointer;
      fill: var(--new-ink-100);
      opacity: 0.8;
    }

    .title {
      margin-bottom: 14px;
      font-weight: 700;
    }

    .subtitle {
      display: block;
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: 700;
      color: var(--new-yellow-50);
    }

    .settings {

      .settingItem {
        margin-bottom: 24px;
      }

      .settingName {
        display: block;
        margin-bottom: 4px;
        font-size: 12px;
        font-weight: 600;
      }

      .settingInput {
        height: 28px;
        font-size: 14px;
      }

    }

    .actions{
      
      display: flex;
      justify-content: flex-end;
    
      .saveSetting {
        background-color: var(--new-blue-60);
        border: none;
        margin-left: 12px;
        color: var(--new-blue-10);

        &:hover{
          background-color: var(--blue-70);
          color: var(--ink-00);
        }
      }
    
      .cancelSetting {
        // border-color: var(--ink-00);

        &:hover{
          //color: inherit;
        }

      }
    
    }

  } 

}



// Flywheel disabled 
// .card {

//   .ant-menu-item-disabled{
//     cursor: not-allowed !important;
//     color: inherit !important;
//     opacity: 0.8;

//     &.highlightedItem {
//       color: var(--red-70) !important;
//     }
//   }
  
// }
