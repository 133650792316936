
body .actionBtn {
    display: inline-flex;
    border: none;
    padding: 0 0 0 6px;

    &:hover{
        background: none;
    }

    &:disabled{
        background: none;

        .actionIcon {
            fill: rgba(255, 255, 255, 0.48);
        }

        &:hover{
            background: none;
            border: none;
        }

    }

    .actionIcon {
        width: 20px;
        height: 20px;   
        fill: var(--ink-00);
        transition: fill 0.3s ease;
    }



}


body .cancelBtn{
    padding-left: 9px;

    .cancelIcon{
        width: 18px;
        height: 18px;
        fill: var(--red-60);
    }

}
