// Overall orders section
.ordersPanel {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.noOrdersTitle {
  width: 100%;
  padding: 24px 16px 0px 16px;
  text-align: center;
  color: rgba(var(--ink-00-rgb), 0.64);
}

.backgroundImage {
  width: 100%;
  margin-top: 48px;
}

// Orders Tabs and Action buttons
.ordersHeader {
  width: 100%;
  height: 40px;
  display: flex;
  border-bottom: 1px solid var(--new-ink-00);
  position: sticky;
  top: 0;
  background: var(--new-ink-20);
  z-index: 1;
}

.ordersTabs {
  width: 212px;
  display: flex;
  overflow: hidden;
}

.ordersTab {
  width: 72px;
  height: 100%;
  font-size: 12px;
  line-height: 12px;
  padding: 14px 8px;
  color: var(--new-ink-100);
}

.ordersTabActive {
  border-bottom: 2px solid var(--blue-60);
  color: rgba(var(--blue-60-rgb), 0.8);
}

.ordersActions {
  // width: 108px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;

  .ordersAction {
    height: auto;
    padding: 9px 14px;

    .ordersActionIcon {
      fill: var(--ink-00);
      width: 16px;
      height: 16px;
      margin-right: 6px;
      margin-top: 1px;
    }
  }
}

.orderArrowNav {
  width: 32px;
  display: flex;
  justify-content: center;
}

.ordersStats {
  @extend .orderBorder;
  height: 48px;
  display: flex;

  .data {
    font-size: 12px;
    padding: 8px 0px 8px 8px;
  }

  .data:first-child {
    width: 120px;
  }
}

// List of orders
.ordersList {
  //may not need styling on list section
}

// Individual Order
.order {
}

.orderBorder {
  border-bottom: 1px solid var(--new-ink-30);
}

.order > div {
  font-size: 12px;
}

.orderTitle {
  display: flex;
  justify-content: space-between;
}

.orderTitleModal {
  display: flex;
  justify-content: space-between;
  height: 32px;
  padding: 4px 4px 4px 8px;

  .modalTitle {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .orderActions {
    display: flex;

    .orderActionIcon {
      width: 20px;
      height: 20px;

      i {
        font-size: 20px;
      }
    }

    .cancelButton {
      color: var(--red-60);
      width: 20px;
      height: 20px;

      svg {
        display: block;
        fill: var(--red-60);
        cursor: pointer;
      }
    }
  }
}

.orderTitle > .orderActions {
  display: flex;
  padding: 12px 4px 0px 0px;
  position: relative;

  .orderActionIcon {
    width: 20px;
    height: 20px;

    i {
      font-size: 20px;
    }
  }

  .cancelButton {
    color: var(--red-60);
    width: 20px;
    height: 20px;
    margin-right: 8px;

    svg {
      display: block;
      fill: var(--red-60);
      margin: auto;
      cursor: pointer;
    }
  }

  .editButton {
    width: 20px;
    height: 20px;
    border: none;
    padding: 0;
    margin-right: 8px;

    svg {
      fill: var(--new-ink-100);
    }
  }

  .editButton:disabled {
    background: inherit;
    svg {
      fill: rgba(var(--ink-00-rgb), 0.32);
    }
  }

  .antButtonDisabled {
    button {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    i {
      color: rgba(var(--ink-00-rgb), 0.32);
      font-size: 20px;
    }
  }
}

.titleText {
  color: rgba(var(--ink-00-rgb), 0.64);
  padding: 12px 4px 4px 8px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
}

.order > .orderDetails {
  display: flex;
  width: 55%;
  color: var(--ink-00);
  padding: 3px 0px 8px 8px;
  line-height: 16px;
}

.statusRow {
  display: flex;
  padding: 0px 0px 8px 8px;
}

.orderStatusBox {
  width: 120px;
  margin: 0 8px;
}

.orderStatusClickable {
  cursor: pointer;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid var(--new-ink-00);
  width: 100%;
  height: 40px;
}

.headerContent {
  flex: 1;
  min-width: 0px;
}

.confirmCancelOptions {
  display: flex;
  width: 100%;

  > button {
    font-size: 14px;
    line-height: 16px;
    padding: 4px;

    &:first-of-type {
      margin-right: 8px;
    }
  }
}

.cancelConfirmMenu {
  padding: 0px;
  width: 215px;

  > li {
    padding: 8px;
  }
}

body .dropdownItemDisabled {
  color: rgba(255, 255, 255, 0.4);

  &:hover {
    background-color: inherit;
    color: rgba(255, 255, 255, 0.4);
  }
}

.bigNum {
  font-size: 20px;
}
