.titleBar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: var(--new-ink-20);
  height: 24px;
  cursor: pointer;
  -webkit-app-region: drag;

  .title {
    text-align: center;
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    -webkit-app-region: no-drag;

    button {
      border: none;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
