.frame {
  display: flex;
  align-items: center;
  justify-content: center;

  &.frameLarge {
    width: 64px;
    height: 64px;
  }

  &.frameMedium {
    width: 32px;
    height: 32px;
  }

  &.frameSmall {
    width: 24px;
    height: 24px;
  }
}

// main diamond shape
.shape {
  position: relative;
  width: 70%;
  height: 70%;
  background: rgba(var(--ink-90-rgb), 0.64);
  // background: rgba(0, 0, 0, 0.32);
  overflow: hidden;
  transform: rotate(45deg);
}

// animated color fill
.scan {
  position: absolute;
  top: 0;
  left: 0;
  animation: scan 2s infinite ease-out;
  background-color: var(--green-60);
  background: linear-gradient(
    180deg,
    rgba(var(--green-60-rgb), 0) 0%,
    rgba(var(--green-60-rgb), 0.8) 35%,
    rgba(var(--green-60-rgb), 1) 100%
  );

  width: 140%;
  height: 140%;
}

// center knockout
.fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: var(--new-ink-20);

  .frameSmall & {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
  }

  .frameMedium & {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
  }

  .frameLarge & {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
  }
}

// scanning animation
@keyframes scan {
  0% {
    transform: rotate(-45deg) translate3d(0, -105%, 0);
  }
  100% {
    transform: rotate(-45deg) translate3d(0, 100%, 0);
  }
}
