/*
  Tabs - covers a selectable tab + tab pane component. This shares very similar
  styling to a antd Menu, so don't get the two mixed up when you're using components.
  Menus do not have panes attached to them.

  Usage:
    https://ant.design/components/tabs/#header

  Design:
    https://www.figma.com/file/eeFxpoSGBRLqDDIAtRMTOhCO/Component-Library?node-id=6%3A70
*/

@at-root {
  .ant-tabs-bar {
    margin-bottom: 0;
    border-bottom-color: var(--new-ink-00);
    display: flex;
    align-items: center;
    flex-flow: row-reverse nowrap;
  }

  .ant-tabs-extra-content {
    margin-left: auto;
    float: unset;
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      margin-right: 0;
      padding: 18px 24px;
      &:hover {
        color: var(--blue-60);
      }
    }
    .ant-tabs-tab-active {
      font-weight: 400;
      color: var(--blue-60);
    }
  }

  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    padding: 0px 16px;
    width: 100%;
    text-align: left;
  }

  .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    left: 1px;
    background-color: var(--blue-60);
  }

  .ant-tabs-bar.ant-tabs-left-bar {
    background: var(--new-ink-20);
    width: 200px;
    border-right: 0;
  }

  .ant-tabs-nav-container {
    padding: 96px 32px 0px 0px;
    width: 100%;
  }

  .ant-tabs-left-content {
    padding-top: 96px;
  }
}
