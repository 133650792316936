.numberSm .size {
  font-size: 10px;
}
.numberLg .size {
  font-size: 16px;
  font-weight: bold;
  padding: 0px 2px 0px 2px;
}

.sizeCell {
  display: flex;
  flex: 1;
  align-content: center;
  background: var(--new-ink-20);
  color: var(--new-ink-100);
  border-radius: 2px;

  &:hover {
    background: var(--new-ink-15);
    cursor: pointer;
  }

  &:active {
    background: var(--new-ink-10);
    cursor: pointer;
  }

  &.disabled {
    background: none;
    cursor: not-allowed;
  }

  .size {
    margin: auto;
    user-select: none;
    display: flex;
    align-items: baseline;

    > span {
      font-weight: 400;
      line-height: 1;
    }
  }
}

.sizeDisabled {
  color: rgba(var(--ink-00-rgb), 0.32);
  line-height: 16px;

  margin: auto;
  align-items: baseline;
}
