.itemUnchecked {
  visibility: hidden;
}

.bullet {
  padding-right: 5px;
  color: var(--blue-60);
}

.list {
  padding: 8px 16px 0px 16px;
  overflow: scroll;
  overflow-x: hidden;
  max-width: 100%;
  background-color: var(--ink-90);
}

.noMatchDiv {
  position: relative;
  width: 90%;
  text-align: center;
  color: #ffffff;
  opacity: 0.64;
  margin: 0 auto;
  padding-top: 56px;
  line-height: normal;
}

/////

.modalRow {
  display: flex;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 10px;
  }

  .dropdownContent {
    animation: growDown 300ms ease-in-out forwards;

    .currencyPairItem {
      padding: 0 12px;
      opacity: 0.8;
      cursor: pointer;

      &:active {
        background: inherit;
      }
    }
  }

  .filter {
    background-color: var(--ink-80);
    padding: 6px 12px;
    border-bottom: 1px solid var(--ink-90);

    input {
      height: 28px;
    }
  }
}

.symbolsContainer,
.tenorsContainer {
  width: 33%;
  padding-right: 15px;

  .buttonAsDropdown {
    height: 32px;
    padding: 4px 4px 4px 8px;
    justify-content: space-between;
    border-color: rgba(var(--ink-00-rgb), 0.24);
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
  }
}

.currenyPair,
.tenor {
  width: 100%;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  //   80% {
  //     transform: scaleY(1.1);
  //   }
  100% {
    transform: scaleY(1);
  }
}
