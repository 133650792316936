/*
  Drawer - the show/hide side panel that overlays the app. Typically used for settings

  Usage:
    https://ant.design/components/drawer/#header

  Design
    https://www.figma.com/file/eeFxpoSGBRLqDDIAtRMTOhCO/Component-Library?node-id=549%3A4
*/

@at-root {
  .ant-drawer {
    .ant-drawer-header,
    .ant-drawer-content {
      background-color: var(--new-ink-30);
    }
  }

  .ant-drawer-header {
    padding: 24px 16px 16px 16px;
    border-bottom: 0;
  }
  .ant-drawer-header-no-title {
    height: 0px;
  }

  .ant-drawer-title {
    font-size: 20px;
    line-height: 24px;
    color: white;
  }

  // close button, absolutely positioned so it's not at the merce of header text alignment
  .ant-drawer-close {
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    color: var(--ink-00);
    line-height: unset;

    &:hover {
      color: inherit;
    }
  }

  .ant-drawer-body {
    padding: 0;
    height: 100%;
  }

  // Adjust the left side of the Settings drawer for scrolling.
  .ant-drawer-wrapper-body {
    background: var(--ink-90);
  }

  // Adjust the right side of the Settings drawer for scrolling.
  .ant-tabs-content {
    background: var(--ink-70);
    border-left-color: var(--ink-90) !important; // Override AntD.
    min-height: 100%;
    padding-bottom: 24px;
    padding-right: 16px;
  }
}
