.layout {
  position: fixed;
  z-index: 800;

  &.topRight {
    top: 16px;
    right: 16px;
  }

  &.bottomRight {
    bottom: 16px;
    right: 16px;
  }
}

.list {
  display: flex;

  .topRight & {
    flex-flow: column-reverse nowrap;
  }

  .bottomRight & {
    flex-flow: column nowrap;
  }
}

.cell {
  margin-top: 8px;
}

.notification {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0 8px;
  width: 320px;
  overflow: hidden;
  border-radius: 3px;
  background-color: var(--ink-30);
  color: var(--ink-90);
}

.indicator {
  width: 4px;
  height: 100%;
  background-color: var(--ink-50);

  &.log {
    background-color: var(--ink-50);
  }
  &.success {
    background-color: var(--teal-60);
  }
  &.warning {
    background-color: var(--yellow-60);
  }
  &.error {
    background-color: var(--red-60);
  }
}

.content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.header {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: inherit;
  text-transform: uppercase;
}

.message {
  margin: 4px 0 0 0;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
}

.actions {
  padding-top: 8px;
  padding-right: 8px;
}

/*
  Notification animations
*/

/* Starting state when entering from above destination */
.enterDown {
  opacity: 0;
  transform: translate3d(0, -20px, 0);
}

/* Starting state when entering from below destination */
.enterUp {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

/* Target resting state + transition while entering */
.enterActive {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition-property: transform, opacity;
  transition-timing-function: ease-in;
}

/* Resting state after entering */
.enterDone {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

/* Starting state when exiting */
.exit {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

/* Target state when exiting to the right of origin */
.exitRightActive {
  opacity: 0;
  transform: translate3d(30px, 0, 0);
  transition-property: transform, opacity;
  transition-timing-function: ease-in;
}
