.container {
  height: 100vh;
  display: grid;
  grid-template-areas:
    "header header"
    "currency orders"
    "analyticsPanel orders";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr auto;

  // @media screen and (min-width: 1600px), screen and (min-height: 1024px) {
  //   grid-template-areas:
  //     "header header"
  //     "currency orders"
  //     "analyticsPanel analyticsPanel";
  // }

  > .header {
    grid-area: header;
  }

  > .currency {
    grid-area: currency;
    overflow-y: auto;
  }

  > .orders {
    grid-area: orders;
    width: var(--ordersPanelWidth);
    border-left: 1px solid var(--new-ink-00);
  }

  .hiddenPanel {
    display: none;
  }

  > .analytics {
    grid-area: analyticsPanel;
    border-top: 1px solid var(--new-ink-00);
    position: relative;
    min-width: 0;

    .tabsHeader {
      padding: 0px;
      height: 46px;
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      min-width: 0;
      overflow: hidden;
    }

    .ant-tabs-bar {
      .ant-tabs-nav-container {
        padding: 0;
        margin-left: 24px;
      }
    }

    //!!! TODO remove - hack until we replace analyticsPanel tabs with pagebar
    .ant-tabs-nav .ant-tabs-tab {
      // padding: 14px 24px;
      font-size: 12px;
      // line-height: 12px;
      font-weight: normal;
    }
    //--------------

    .ant-tabs-ink-bar {
      bottom: 2px;
    }

    .selectedRowsCards {
      flex: 1;
      overflow: hidden;
      margin-left: 16px;
    }

    .selectedRowsFlexList {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      height: 100%;
    }

    .selectedRowsItem {
      height: 42px;
      background-color: var(--new-ink-20);
      color: var(--new-ink-100);
      margin: 2px;
      padding: 4px;
      border-radius: 4px;
      font-size: 12px;
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      .selectedRow {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        .selectedRowsValue {
          color: var(--teal-60);
        }
      }
    }
  }

  .analyticsPanel {
    // 46px is the computed height of the panel header. Needed here to avoid a vertical scroll bar.
    height: calc(100% - 46px);
    position: relative;
  }

  .resizeIconHoverArea {
    width: 100%;
    height: 8px;
    top: -4px;
    background: transparent;
    position: absolute;

    &:hover {
      cursor: ns-resize;
    }
  }
}
