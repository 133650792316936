.header {
  padding: 8px 16px 0px 16px;
  border-bottom: 1px solid var(--ink-90);
  background-color: var(--ink-70);
  border-radius: 2px 2px 0px 0px;
  .headerItem {
    margin: 8px 0px;
    opacity: 0.64;
  }
}

.content {
  padding: 16px;

  .titles {
    display: flex;
    margin-bottom: 9px;

    .title {
      width: 33%;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
    }
  }

  .addRow {
    padding: 0;
    border: none;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: 400;
  }
}

.footer {
  padding: 8px 16px;
  border-top: 1px solid var(--ink-90);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  background-color: var(--ink-70);
  border-radius: 0px 0px 2px 2px;
}

.footerElement {
  margin-left: 8px;
  min-width: 64px;
}
