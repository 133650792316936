/*
  Table - the woe that is tables. The goal here is simple; we wan't to do the base styling
  for tables that does not account for ANY special case (e.g. Market UI). This means the basics,
  headers, cell padding, borders, and alternate row colors. Anything custom in a table row/cell should
  be styled by THAT component only. Avoid edge case rules

  Usage:
    https://ant.design/components/table/#header

  Design:
    https://www.figma.com/file/bDra0jQUuM6KqDA2CPNIvU7e/Documentation?node-id=40%3A0
*/

@at-root {
  .ant-table-thead > tr:first-child > th:last-child {
    border-radius: 0;
  }

  /*
    Table Filter
    - shares some classes from dropdown.scss. to avoid clashing,
    we use the parent .ant-table-filter-dropdown selector
  */

  // filter icon - jesus antd went in hard on rule specificity here...
  .ant-table .ant-table-thead .ant-table-column-has-actions.ant-table-column-has-filters {
    .anticon-filter {
      display: none;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: transparent;
      color: white;

      &.ant-table-filter-open {
        color: inherit;
        background-color: inherit;
      }
    }

    &:hover {
      .anticon-filter {
        display: block;

        &:hover,
        &:focus {
          color: white;
          background-color: rgba(var(--ink-00-rgb), 0.16);
        }
      }
    }

    &:active .anticon-filter:hover {
      color: inherit;
      background-color: rgba(var(--ink-00-rgb), 0.24);
    }
  }

  // filter dropdown menu
  .ant-table-filter-dropdown {
    border-radius: 4px;
    overflow: hidden;

    // apply button at the bottom of the filter menu
    .ant-table-filter-dropdown-btns {
      display: flex;
      flex-flow: row-reverse nowrap;
      border-top-color: var(--new-ink-00);
      background-color: var(--new-ink-30);
    }
  }

  // empty table message
  .ant-table-placeholder {
    padding: 0;
    background-color: transparent;
    border: none;
  }
}
