.orderProgressBar {
  width: 100%;
  height: 24px;
  position: relative;
  background-color: var(--new-ink-20);
  border-radius: 16px;
  overflow: hidden;
  .bar {
    background-color: var(--ink-60);
    height: 100%;
    position: absolute;
    line-height: inherit;
    border-radius: 16px;
    transition: width 1s;
  }
}

.progressInfo {
  position: absolute;
  text-align: right;
  color: var(--ink-00);
  right: 0;
  height: 24px;
  padding: 2px 16px 2px 0;
  line-height: 20px;
}
