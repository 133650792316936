/*
  Switch Input - standard switch on/off component.

  Usage:
    https://ant.design/components/switch/#header

  Design
    https://www.figma.com/file/eeFxpoSGBRLqDDIAtRMTOhCO/Component-Library?node-id=151%3A16
*/
@at-root {
  .ant-switch {
    height: 24px;
    border: 1px solid rgba(var(--ink-00-rgb), 0.48);
    background-color: transparent;

    // actual part that handles the switch location, careful with the 'left/right' values
    &.ant-switch-checked {
      background-color: rgba(var(--ink-00-rgb), 0.48);

      &::after {
        left: 100%;
        margin-left: -3px;
      }
    }

    // knob
    &::after {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 16px;
      height: 16px;
      border: 0;
      background-color: var(--ink-00);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    }
  }
}
